import React, {Component, PureComponent} from 'react';
import Util from "../../utils/CommonUtil";
import CheckBoxCellRenderer from "../GridCellRenderers/CheckBoxCellRenderer";
import DateTimeCellRenderer from "../GridCellRenderers/DateTimeCellRenderer";
import CurrencyCellRenderer from "../GridCellRenderers/CurrencyCellRenderer";
import {AllModules} from "@ag-grid-enterprise/all-modules";
import {AgGridReact} from "@ag-grid-community/react";
import styles from './Grid.module.css'
import {connect} from "react-redux";
import CustomThemesToolPanel from "./CustomThemesToolPanel";
import ImageCellRenderer from "../GridCellRenderers/ImageCellRenderer";
import ImageToolTip from "../GridCellRenderers/ImageToolTip";

class Grid extends Component {
  constructor(props) {
    super(props);

    this.autoGroupColumnDef= {
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {footerValueGetter: '"Toplam (" + x + ")"'},
      comparator:Util.GRID_COLUMN_COMPARATOR
    }

    this.state = {
      currentRowInfo:'',
      filterInfo:''
    };

    this.sideBar= {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'themes',
          labelDefault: 'Temalar',
          labelKey: 'themes',
          iconKey: 'theme-icon',
          toolPanel: 'customThemesToolPanel',
        }
      ]
    }

    this.gridApi=null;
    this.gridColumnApi=null;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const propKeys= Object.keys(this.props);
    for(let i=0;i<propKeys.length;i++) {
      if(typeof this.props[propKeys[i]] === 'function') {
        continue;
      }

      if(this.props[propKeys[i]]!== nextProps[propKeys[i]] ) {
        //console.log('Key:'+ propKeys[i] + ' için farklılık tespit edildi. Render edilecektir. this.props[propKeys[i]]:',this.props[propKeys[i]],'nextProps[propKeys[i]]:',nextProps[propKeys[i]]);
        return true;
      }
    }

    const stateKeys= Object.keys(this.state);
    for(let i=0;i<stateKeys.length;i++) {
      if(typeof this.state[stateKeys[i]] === 'function') {
        continue;
      }

      if(this.state[stateKeys[i]]!== nextState[stateKeys[i]] ) {
        return true;
      }
    }

    return false;
  }

  getRowStyle=(params) => {
    if(params.node.footer) {
      return { background: 'LightGray' };
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi=params.columnApi;
    if(this.props.columnsSizeAuto){
      this.gridApi.sizeColumnsToFit();
    }
    if(this.props.onGridReadyCustom) {
      this.props.onGridReadyCustom(params);
    }
  };

  onColumnRowGroup=(params) => {
    this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllDisplayedColumns()[0].colDef);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.gridApi)
    {
      this.gridApi.resetRowHeights();
      this.gridApi.refreshCells();
      this.gridApi.refreshHeader();
    }
  }

  selectionChanged= (params) => {
    this.refreshSummaryBarInfo();
    /*if(params.api.selectionController && params.api.selectionController.lastSelectedNode){
      this.refreshSummaryBarInfo();
    }
    */
  }

  refreshSummaryBarInfo=()=> {
    if(this.gridApi /*&& !this.gridApi.selectionController.lastSelectedNode.group*/) {
      const selectedNodeIndex= this.getSelectedNodeIndex();
     const curIndexStr= Util.checkNullOrUndefined(selectedNodeIndex) ? '-' : (selectedNodeIndex+1).toString() ;
      this.setState({currentRowInfo: ( curIndexStr +' / ' +  this.gridApi.getDisplayedRowCount()) });
    }else {
      this.setState({currentRowInfo: '' });
    }
  }

  render() {
    return <div style={{
      height: '100%',
      width: '100%',
    }}>
      <div
        id="myGrid"
        style={{
          height: '100%',
          width: '100%',
        }}
        className={this.props.selectedGridTheme}
      >

        <AgGridReact
          {...this.props}
          frameworkComponents={{
            ImageToolTip: ImageToolTip,
            ImageCellRenderer: ImageCellRenderer,
            customThemesToolPanel: CustomThemesToolPanel,
            CheckBoxCellRenderer: CheckBoxCellRenderer,
            DateTimeCellRenderer: DateTimeCellRenderer,
            CurrencyCellRenderer: CurrencyCellRenderer
          }}
          modules={AllModules}
            localeText={Util.agGridLocaleTextTR}
          onGridReady={this.onGridReady}
          getRowStyle={this.props.getRowStyle ? this.props.getRowStyle : this.getRowStyle}
          onColumnRowGroupChanged={this.onColumnRowGroup}
          suppressMakeColumnVisibleAfterUnGroup={true}
          sideBar={this.sideBar}
          onSelectionChanged={this.selectionChanged}
          defaultGroupSortComparator={Util.GRID_GROUP_SORT_COMPARATOR}
          //onModelUpdated={(modelUpdatedEventParams ) => this.refreshSummaryBarInfo() }
          onFilterChanged={this.props.onFilterChanged}
        />
      </div>

      {this.props.summaryBar !== false &&
      <div className={styles.gridSummaryBar}>


        <div>
          {this.state.currentRowInfo}
        </div>

        <div>
          {this.state.filterInfo}
        </div>

      </div>
      }
    </div>
  }

  getSelectedNodeIndex() {
    if(!this.gridApi)
      return null;

    const selectedNodes=this.gridApi.getSelectedNodes();
    if(selectedNodes.length===0)
      return null;

    let filterInd;
    this.gridApi.forEachNodeAfterFilter((node,ind) => {
        if(node===selectedNodes[0]) {
          filterInd=ind;
        }
    });


    //const currIndex= selectedNodes.length > 0 ? selectedNodes[0].rowIndex : null;  //this.gridApi.selectionController.lastSelectedNode?.childIndex;
    return filterInd;
  }
}

const mapStateToProps = (state) => {
  //console.log('(Grid.js) mapStateToProps state : ', state);
  return {
    selectedGridTheme: state.selectedGridTheme
  }
}

export default connect(mapStateToProps)(Grid);
