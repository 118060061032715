//import React from 'react';
//import axios from "axios";

//var say = 0;

// const Button = props => (
//     <button className="our_button" onClick={props.onClick}>
//         {props.label}
//     </button>
// );

//  export default Contacts;


import {Consts} from "../const/Consts";
import moment from "moment";

const Util = {

  HEADERS_FOR_GET: {
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + sessionStorage.getItem('token')
  },
  currencyFormatter: new Intl.NumberFormat("tr", {maximumFractionDigits: 2, minimumFractionDigits: 2}),
  miktarFormatter: new Intl.NumberFormat("tr", {maximumFractionDigits: 5, minimumFractionDigits: 0}),

  apiRequest: async function apiRequest(url, method, headers, body) {

    let ret;
    try {
      const response = await fetch(url, {
        method: method,
        headers: headers,
        body: body
      });
      ret = await response.json();
    } catch (e) {
      console.error("An error occured for url:" + url + "at apiRequest Method", e);
      if (e.message === 'Failed to fetch') {
        ret = {result:Consts.RESULT_ERROR,resultMessage: "Sunucuya erişilemiyor"};
      } else {
        ret = {result:Consts.RESULT_ERROR,resultMessage: "Sunucu ile bağlantı kurulurken bir hata oluştu!"};
      }
    }
    if (ret.error) {
      console.error("Sunucu problemi oluştu Result:", ret);
      if (ret.error_description === 'Bad credentials') {
        ret = {result:Consts.RESULT_ERROR,resultMessage: "Giriş bilgileri yanlış veya hesap aktive edilmedi."};
      } else if (ret.error === 'unauthorized') {
        ret = {result:Consts.RESULT_ERROR,resultMessage: "Yetki problemi oluştu."};
      } else {
        ret = {result:Consts.RESULT_ERROR,resultMessage: "Sunucu problemi oluştu."};
      }
    }

    return ret;
  },
  toDataURL: url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })),
  postForImage:async (url,params) => {
//    reportDataParamsInput.value = CommonUtil.b64EncodeUnicode(JSON.stringify(this.props.reportDataParams))  //encodeURIComponent( JSON.stringify(this.props.reportDataParams));

    //const encodedPostBody = 'parameters=' +   encodeURIComponent( JSON.stringify(params)); // encodeURIComponent(JSON.stringify(reportDataParams));
    const encodedPostBody =  JSON.stringify(params); // encodeURIComponent(JSON.stringify(reportDataParams));


    //console.log('xml', xml,'reportDataParams', reportDataParams);
    //console.log('this',this,'encodedPostBody',encodedPostBody,'encodeURI(this.props.xml)',encodeURI(this.props.xml));

    await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: encodedPostBody
    }).then(response => {
      console.log('postForImage response', response);

      //const fileName = reportDataParams.exportParameters.fileName + moment().format('YYYY_MM_DD_HH_MM') + extension;
      const fileName = "qr.jpg";

      //const filename =  response.headers.get('Access-Control-Expose-Headers').split('filename=')[1];
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.target="_blank"
        a.click();
        a.remove();
      });
    });
  },
  GRID_DATE_COMPARATOR :function(filterLocalDateAtMidnight, cellValue) {
    const cellDateStr=moment(cellValue).format('YYYY-MM-DD');
    const filterDateStr=moment(filterLocalDateAtMidnight).format('YYYY-MM-DD');

    if(cellDateStr===filterDateStr)
      return 0;

    if(cellDateStr>filterDateStr)
      return 1;

    if(cellDateStr<filterDateStr)
      return -1;
  }  ,
  GRID_COLUMN_COMPARATOR: function(valueA, valueB, nodeA, nodeB, isInverted) {
    if(typeof valueA ==='string' && typeof valueB ==='string') {
      const valA= !valueA ? '' : valueA.toLocaleLowerCase('tr').trim();
      const valB= !valueB ? '' : valueB.toLocaleLowerCase('tr').trim();
      return valA.localeCompare(valB);
    }

    if(valueA>valueB)
      return 1;
    if(valueA===valueB)
      return 0;
    if(valueA<valueB)
      return -1;

  },
  GRID_GROUP_SORT_COMPARATOR: function(valueA, valueB) {
    if(typeof valueA.key ==='string') {
      const valA= !valueA.key ? '' : valueA.key.toLocaleLowerCase('tr').trim();
      const valB= !valueB.key ? '' : valueB.key.toLocaleLowerCase('tr').trim();
      return valA.localeCompare(valB);
    }

    if(valueA.key>valueB.key)
      return 1;
    if(valueA.key===valueB.key)
      return 0;
    if(valueA.key<valueB.key)
      return -1;

  },
  GRID_GROUP_KEY_CREATOR: function(params) {
    if(typeof params.value ==='string') {
      return  !params.value ? '' : Util.capitalizeFirstLetter(params.value.toLocaleLowerCase('tr').trim()) ;
    }

    return params.value;
  },
  capitalizeFirstLetter: function (string) {
    if(!string)
      return string;

    return string.charAt(0).toLocaleUpperCase('tr') + string.slice(1);
  },
  parseJwt: function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  },
  isTrue: function isTrue(obj) {
    return (obj && obj === true);
  },
  isAdminUser: function isElevatorUser() {
    return JSON.parse(sessionStorage.getItem("profile"))?.role === 'admin';
  },
  isElevatorUser: function isElevatorUser() {
    return JSON.parse(sessionStorage.getItem("profile"))?.role === 'elevator_user';
  },
  isZorluUser: function isElevatorUser() {
    return JSON.parse(sessionStorage.getItem("profile"))?.role === 'zorlu_user';
  },
  getProjectId: function getProjectId() {
    return JSON.parse(sessionStorage.getItem("profile"))?.projectId;
  },
  a11yProps: function a11yProps(index) {
    //Material Ui Tab componenti için
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  },
  compareByField(toBeSortedField, a, b) {
    if (a[toBeSortedField] < b[toBeSortedField]) {
      return -1;
    }
    if (a[toBeSortedField] > b[toBeSortedField]) {
      return 1;
    }
    return 0;
  },
  agGridLocaleTextTR : {
    // for filter panel
    page: 'Sayfa',
    more: 'Devamı',
    to: 'To',
    of: 'Of',
    next: 'İleri',
    last: 'Son',
    first: 'İlk',
    previous: 'Sonraki',
    loadingOoo: 'Yükleniyor...',

    // for set filter
    selectAll: 'Tümünü seç',
    searchOoo: 'Ara...',
    blanks: 'Boş',

    // for number filter and text filter
    filterOoo: 'Süz...',
    applyFilter: 'Süzme...',
    equals: 'Eşittir',
    notEquals: 'Eşit değilse',

    lessThan: 'Küçüktür',
    greaterThan: 'Büyüktür',
    lessThanOrEqual: 'Küçük eşit',
    greaterThanOrEqual: 'Büyük eşit',
    inRange: 'Aralık',

    // for text filter
    contains: 'İçeriyorsa',
    notContains: 'İçermiyorsa',
    startsWith: 'Başlıyorsa',
    endsWith: 'Bitiyorsa',

    // the header of the default group column
    group: 'Grup',

    // tool panel
    columns: 'Sütunlar',
    filters: 'Süzme',
    rowGroupColumns: 'Pivot Sütun',
    rowGroupColumnsEmptyMessage: ' Sütunları gruba sürükle',
    valueColumns: 'Sütun Değeri',
    pivotMode: 'Pivot-Modu',
    groups: 'Gruplar',
    values: 'Değerler',
    pivots: 'Pivotlar',
    valueColumnsEmptyMessage: ' toplamak için sütunları sürükleyin',
    pivotColumnsEmptyMessage: ' pivot için buraya sürükleyin',
    toolPanelButton: 'Araç çubuğu',

    // other
    noRowsToShow: ' Daha önce veri eklenmemiş.',

    // enterprise menu
    pinColumn: 'Sütunu sabitle',
    valueAggregation: 'Değer',
    autosizeThiscolumn: 'Sığdır',
    autosizeAllColumns: 'Sütunları otomatik boyutlandır',
    groupBy: 'Grupla',
    ungroupBy: 'Gruplama',
    resetColumns: 'Sütunları sıfırla',
    expandAll: 'Hepsini aç',
    collapseAll: 'Hepsini kapat',
    toolPanel: 'Araç paneli',
    export: 'Çıkart',
    csvExport: 'CSV ye çıkar',
    excelExport: 'Excel e aktar',

    // enterprise menu pinning
    pinLeft: 'Sola yasla <<',
    pinRight: 'Sağa yasla >>',
    noPin: 'Yaslama <>',

    // enterprise menu aggregation and status panel
    sum: 'Sum',
    min: 'En az',
    max: 'En çok',
    none: 'Yok',
    count: 'Say',
    verage: 'Ortalama',

    // standard menu
    copy: 'Kopyala',
    copyWithHeaders: 'Başlığı kopyala',
    ctrlC: 'ctrl C',
    paste: 'Yapıştır',
    ctrlV: 'ctrl C'
  },


// callApiPostNew: function callApiPost(dat){
  //     const URLSearchParams = Object.keys(dat).map((key) => {
  //         return encodeURIComponent(key) + '=' + encodeURIComponent(dat[key]);
  //     }).join('&');
  //
  //     return new Promise((resolve, reject) => {
  //         axios
  //             .request({
  //                 url: "/customermanagement/search-company?search=true",
  //                 method: "post",
  //                 baseURL:
  //                     "http://localhost:8015",
  //                 headers: { "Content-Type": "application/json"  },
  //                 data: {
  //                     companyDesc: null,
  //                     companyName: null,
  //                     companyType: null,
  //                     domainRef: null,
  //                     status: 1
  //                 }
  //             }).then(response => {
  //                 if (response.data) {
  //                     resolve(response.data);
  //                 }
  //             }
  //         ).catch(error => {
  //             reject (error);
  //         });
  //     });
  // },
  // callApiPost: function callApiPost(dat){
  //     const URLSearchParams = Object.keys(dat).map((key) => {
  //         return encodeURIComponent(key) + '=' + encodeURIComponent(dat[key]);
  //     }).join('&');
  //
  //     return new Promise((resolve, reject) => {
  //         axios
  //             .request({
  //                 url: "/mikrokom2/RestController",
  //                 method: "post",
  //                 baseURL:
  //                     "http://192.168.2.111:8090",
  //                 headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
  //                 data: URLSearchParams
  //             }).then(response => {
  //                 if (response.data) {
  //                     resolve(response.data);
  //                 }
  //             }
  //         ).catch(error => {
  //             reject (error);
  //         });
  //     });
  // },
  // colToSum: function colToSum(props,colIndex)
  // {
  //     var body = document.getElementById(props.bodyId);
  //     var sum = 0;
  //     for (let i = 0; i < body.rows.length; i++) {
  //         let deger =0;
  //         if(body.rows[i].cells[colIndex].type === "input")
  //         {
  //             deger = body.rows[i].cells[colIndex].firstChild.value;
  //             if(deger.trim()!==""){
  //                 sum += Number(deger);
  //             }
  //         }else{
  //             deger = body.rows[i].cells[colIndex].textContent;
  //             if(deger.trim()!==""){
  //                 sum += Number(deger.replace('.','').replace(',','.'));
  //             }
  //         }
  //
  //         /*let deger = body.rows[i].cells[colIndex].type === "input"
  //                     ?
  //                         body.rows[i].cells[colIndex].firstChild.value
  //                     :
  //                         body.rows[i].cells[colIndex].textContent;
  //             if(deger.trim()!=="")
  //             {
  //                 sum += Number(deger.replace('.','').replace(',','.'));
  //             }
  //         */
  //     }
  //     var foot = document.getElementById(props.footId);
  //     foot.rows[0].cells[colIndex].textContent = this.formatMyMoney(sum);
  // },
  // delRow: function delRow(props, e, rowIndex) {
  //     try {
  //         var table = document.getElementById(props.bodyId);
  //
  //         table.deleteRow(rowIndex);
  //
  //         for (let i = 0; i < (table.rows.length-1); i++) {
  //             table.rows[i].cells[0].innerHTML = i+1;
  //         }
  //
  //         if(table.rows.length === rowIndex)
  //         {
  //             Util.addRow(props, e, table.rows.length,true)
  //         }else
  //         {
  //             Util.setRowCell(rowIndex, 1, props.bodyId);
  //         }
  //     } catch (error) {
  //         alert(error);
  //         return false;
  //     }
  //     return true;
  // },
  // addRow: function addRow(props, e, rowIndex, cellFocus) {
  //     var table = document.getElementById(props.bodyId);
  //     var rowCnt = table.rows.length;
  //     var siraNoYenile = false;
  //
  //     if(rowIndex!== rowCnt)
  //     {
  //         siraNoYenile=true;
  //         rowCnt = rowIndex;
  //     }
  //     siraNoYenile=true;
  //
  //     var row = table.insertRow(rowCnt);
  //     var idx="cid " + rowCnt;
  //     for (let index = 0; index < 6; index++) {
  //         var cell1 = row.insertCell(index);
  //         if(index===0)
  //         {
  //             cell1.id=  idx;
  //             cell1.className = "siraNo";
  //             cell1.innerHTML = "*";
  //         }else if (index > 3){
  //             cell1.className = "numeric";
  //             cell1.innerHTML = "";
  //         }else
  //         {
  //             cell1.className = "string";
  //             cell1.innerHTML = "";
  //         }
  //     }
  //
  //     if(siraNoYenile)
  //     {
  //         for (let i = 0; i < (table.rows.length-1); i++) {
  //             table.rows[i].cells[0].innerHTML = i+1;
  //         }
  //     }
  //
  //     if(cellFocus)
  //     {
  //         Util.setRowCell(rowCnt, 1,props.bodyId);
  //     }
  //
  //     return;
  // },
  // formatMyMoney: function formatMyMoney(price) {
  //
  //     var currency_symbol = "â‚º"
  //
  //     var formattedOutput = new Intl.NumberFormat('tr-TR', {
  //         style: 'currency',
  //         currency: 'TRY',
  //         minimumFractionDigits: 2,
  //     });
  //
  //     return formattedOutput.format(price).replace(currency_symbol, '')
  // },
  // inputOldValues: function formatMyMoney(td, input,tmpColName) {
  //     var attrOldValue = document.createAttribute('oldValue');
  //     var attrCellIndex = document.createAttribute('cellIndex');
  //     var attrRowIndex = document.createAttribute('rowIndex');
  //     var attrColName = document.createAttribute('colName');
  //
  //     attrOldValue.value  = td.innerHTML.trim();
  //     attrCellIndex.value = td.cellIndex;
  //     attrRowIndex.value  = td.parentNode.sectionRowIndex;
  //     attrColName.value = tmpColName;
  //
  //     input.setAttributeNode(attrCellIndex);
  //     input.setAttributeNode(attrRowIndex);
  //     input.setAttributeNode(attrOldValue);
  //     input.setAttributeNode(attrColName);
  // },
  // setRowCell: function setRowCell(rowIndex, cellIndex, bodyId){
  //     var tmpOldRow = this.getBodyRow(rowIndex, bodyId);
  //     var tmpOldCell= tmpOldRow.cells[cellIndex]; // .attributes.editable.value;
  //
  //     setTimeout(function(){
  //         tmpOldCell.focus();
  //         tmpOldCell.click();
  //
  //         tmpOldRow.scrollIntoView({
  //             behavior: 'smooth',
  //             block: 'center'
  //         });
  //     },10);
  // },
  // getBodyRow: function getBodyRow(rowIndex, bodyId){
  //     var tmpGrd = document.getElementById(bodyId);
  //     return tmpGrd.rows[rowIndex];
  // },
  // setTabButton: function setTabButton(e){
  //     if(e.target.nodeName==="IMG")
  //         return;
  //
  //     var tabId= ">" + e.target.id;
  //     var i, tabcontent, tablinks;
  //     tabcontent = document.getElementsByClassName("tabcontent");
  //     for (i = 0; i < tabcontent.length; i++) {
  //         tabcontent[i].style.display = "none";
  //     }
  //     tablinks = document.getElementsByClassName("tablinks");
  //     for (i = 0; i < tablinks.length; i++) {
  //         tablinks[i].className = tablinks[i].className.replace(" active", "");
  //     }
  //     document.getElementById(tabId).style.display = "block";
  //     e.currentTarget.className += " active";
  // },
  // setTabButton2: function setTabButton2(tabId,cln){
  //     var i, tabcontent, tablinks;
  //     tabcontent = document.getElementsByClassName("tabcontent");
  //     for (i = 0; i < tabcontent.length; i++) {
  //         tabcontent[i].style.display = "none";
  //     }
  //     tablinks = document.getElementsByClassName("tablinks");
  //     for (i = 0; i < tablinks.length; i++) {
  //         tablinks[i].className = tablinks[i].className.replace(" active", "");
  //     }
  //     document.getElementById(tabId).style.display = "block";
  //     cln.className += " active";
  // },
  // addMainTabFisGiris: function addMainTabFisGiris(e){
  //     say = say+1;
  //     var f= document.getElementById("mainTabMenu");
  //     var cln = f.firstChild.cloneNode(true);
  //     //cln.removeAttribute("id");
  //     cln.id= "fis" + String(say);
  //     cln.innerHTML= cln.innerHTML.replace("HoÅŸ Geldiniz","FiÅŸ GiriÅŸi");
  //     cln.firstElementChild.removeAttribute("onclick");
  //     function setImgOnClik(e){
  //         Util.imgOnClick(e);
  //     }
  //     cln.firstElementChild.onclick= setImgOnClik;
  //     cln.className="tablinks";
  //     cln.hidden = false;
  //     cln.removeAttribute("onclick")
  //     function setTabButton(e){
  //         Util.setTabButton(e);
  //     }
  //     cln.onclick = setTabButton;
  //     document.getElementById("mainTabMenu").appendChild(cln);
  //
  //     var iiDiv = document.createElement('div');
  //     iiDiv.className = 'tabcontent';
  //     iiDiv.id=">fis" + String(say);
  //     iiDiv.innerHTML ="<iframe src='http://localhost:3000/fis' width='100%' height='100%' frameborder='0'></iframe>";
  //     var s = document.getElementById('frmMain');
  //     s.appendChild(iiDiv);
  //     this.setTabButton2(iiDiv.id, cln);
  // },
  // addMainTabHesPlan: function addMainTabHesPlan(e){
  //     say = say+1;
  //     var f= document.getElementById("mainTabMenu");
  //     var cln = f.firstChild.cloneNode(true);
  //     //cln.removeAttribute("id");
  //     cln.id= "hesPlan" + String(say);
  //     cln.innerHTML= cln.innerHTML.replace("HoÅŸ Geldiniz","Hesap PlanÄ± Ä°ÅŸlemleri");
  //     cln.firstElementChild.removeAttribute("onclick");
  //     function setImgOnClik(e){
  //         Util.imgOnClick(e);
  //     }
  //     cln.firstElementChild.onclick= setImgOnClik;
  //     cln.className="tablinks";
  //     cln.hidden = false;
  //     cln.removeAttribute("onclick")
  //     function setTabButton(e){
  //         Util.setTabButton(e);
  //     }
  //     cln.onclick = setTabButton;
  //     document.getElementById("mainTabMenu").appendChild(cln);
  //
  //     var iiDiv = document.createElement('div');
  //     iiDiv.className = 'tabcontent';
  //     iiDiv.id=">hesPlan" + String(say);
  //     iiDiv.innerHTML ="<iframe src='http://localhost:3000/hesPlan' width='100%' height='100%' frameborder='0'></iframe>";
  //     var s = document.getElementById('frmMain');
  //     s.appendChild(iiDiv);
  //     this.setTabButton2(iiDiv.id, cln);
  // },
  // imgOnClick: function imgOnClick(e)
  // {
  //     var tabName= e.currentTarget.parentNode.innerText.trim();
  //     var cevap = window.confirm(tabName +  " penceresi kapatÄ±lsÄ±n mÄ±?");
  //     if (cevap) {
  //         var tabId= e.currentTarget.parentNode.id;
  //         var tabElm = document.getElementById(tabId);
  //         var tabprv = tabElm.previousSibling;
  //         if(tabId!=="welcome")
  //         {
  //             tabElm.remove(tabElm);
  //             var tabDiv = document.getElementById(">"+ tabId);
  //             tabDiv.remove(tabDiv);
  //             this.setTabButton2(">" + tabprv.id, tabprv);
  //         }
  //         else
  //         {
  //             tabElm.hidden=true;
  //         }
  //     }
  // }

  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
  checkNullOrUndefined(token) {
    if(token===null || token===undefined)
      return true;
    return false;
  },
  printKeysAndValues(obj) {
    if(!obj)
      return "";

    let str="";
    for(const key of  Object.keys(obj)) {
      str+= " "+ key+":"+ obj[key]+" ";
    }
    return str;
  },
  prepareRowFromHeaderAndRows(headers, rows) {
    const startMillis = new Date().getTime();
    if (!headers || headers.length === 0 || !rows || rows.length === 0)
      return [];

    const rowJsonArr = [];
    rows.forEach((row) => {
      const rowJson = {}
      headers.forEach((header, index) => {
        rowJson[header.name] = row[index];

        if (rowJson[header.name] && header.pgType === 'date')
          rowJson[header.name] += 'T00:00';
      });

      rowJsonArr.push(rowJson);
    });
    console.debug('prepareRowFromHeaderAndRows süresi: ', new Date().getTime() - startMillis);
    // console.log('rowJsonArr : ', rowJsonArr);
    return rowJsonArr;
  },
}

export default Util;
