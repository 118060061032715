import React, {Component} from 'react';
import styles from './Opportunity.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import LoginUtil from "../../utils/LoginUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import CancelIcon from '@material-ui/icons/Cancel';

class Opportunity extends Component {
  constructor(props) {
    super(props);

    const categoryColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.categoryId',
        hide: true
      },
      {
        headerName: 'Kategori',
        field: 'name',
        cellRenderer: "loadingCellRenderer",
        resizable: true,
        flex: 1
      }
    ];

    const productColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.productId',
        hide: true
      },
      {
        headerName: 'Ürün',
        field: 'name',
        cellRenderer: "loadingCellRenderer",
        resizable: true,
        flex: 1
      }
    ];

    const brandColumnDefs = [
      {
        headerName: 'Marka',
        field: 'brand',
        resizable: true,
        flex: 1
      }
    ];

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Fırsat Id',
        field: 'id',
        valueGetter: 'data.opportunityId'
      },
      {
        headerName: 'Başlık',
        field: 'title'
      },
      {
        headerName: 'Açıklama',
        field: 'description'
      },
      {
        headerName: 'Banner',
        field: 'imageFileName',
        cellRenderer: 'ImageCellRenderer'
      },
      {
        headerName: 'Slider',
        field: 'showSlider',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Pasif',
        field: 'deleted',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Kategori',
        field: 'categoryName'
      },
      {
        headerName: 'Ürün',
        field: 'productName'
      },
      {
        headerName: 'Marka',
        field: 'brand'
      },
      {
        headerName: 'Öncelik Değeri',
        filter: 'agNumberColumnFilter',
        field: 'priority'
      }
    ];

    this.inputParams = {
      title: {
        label: 'Başlık', type: 'text', defaultValue: ''
      },
      description: {
        label: 'Açıklama', type: 'text', defaultValue: ''
      },
      category: {
        label: 'Kategori', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: categoryColumnDefs,
          urlEndPoint: "/category/get-categories-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Kategori Seçiniz',
          onSelection: this.onCategorySelection.bind(this)
        },
      },
      brand: {
        label: 'Marka', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: brandColumnDefs,
          urlEndPoint: "/product/get-brand-list-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Marka Seçiniz',
          onSelection: this.onBrandSelection.bind(this)
        },
      },
      product: {
        label: 'Ürün', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: productColumnDefs,
          urlEndPoint: "/product/list-for-panel-with-project-id-for-popmodal",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Ürün Seçiniz',
          onSelection: this.onProductSelection.bind(this)
        },
      },
      showSlider: {
        label: 'Slider', defaultValue: 'false', type: 'select',
        options: [
          {label: 'Evet', value: 'true'},
          {label: 'Hayır', value: 'false'}
        ]
      },
      imageFileName: {
        label: 'Banner', type: 'image', defaultValue: ''
      },
      currImage: {
        label: 'Mevcut Banner', type: 'image-viewer'
      },
      priority: {
        label: 'Öncelik Değeri', type: 'number', defaultValue: 0
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    }

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      editData: null
    };

    this.gridSettingJson = localStorage.getItem('opportunityGridSetting');
  }

  onCategorySelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].categoryId;
    formData[parentItemName].label = selectedRow[0].name;
    formData.product.value = 0;
    formData.product.label = '';
    formData.brand.value = '';
    this.setState({formData: formData});
  }

  onBrandSelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].brand;
    formData[parentItemName].label = selectedRow[0].brand;
    formData.category.value = 0;
    formData.category.label = '';
    formData.product.value = 0;
    formData.product.label = '';
    this.setState({formData: formData});
  }

  onProductSelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].productId;
    formData[parentItemName].label = selectedRow[0].name;
    formData.category.value = 0;
    formData.category.label = '';
    formData.brand.value = '';
    this.setState({formData: formData});
  }

  async componentDidMount() {
    await this.loadData().then();
  }

  loadData = async () => {
    this.setState({loading: true});
    let apiRes;

    const projectId = Util.isAdminUser() ? this.state.formData.projectId.value : Util.getProjectId();

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/opportunity/list-for-panel-with-project-id',
      'POST', Util.HEADERS_FOR_GET, projectId);

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      loading: false,
      gridRowData: this.prepareRowData(apiRes)
    })

    if (!this.gridSettingJson)
      this.gridApi.sizeColumnsToFit();
  }

  close = (event) => {
    this.props.closeTab(event, this.props.tabId);
  }

  askForDeleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();

    if (selectedData.length === 0)
      Mesaj.show('Silinecek kayıt seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else
      Mesaj.show(selectedData[0].data.title + ' Fırsatı ' + (makePassive ? 'pasifleştirmek' : 'silmek') +
        ' istiyor musunuz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.deleteRow.bind(this, makePassive));
  }

  deleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();
    let currentRowIndex = 0;
    selectedData.forEach((node) => {
      currentRowIndex = node.rowIndex - 1;
      this.delData(node.data.opportunityId, makePassive, currentRowIndex).then();
    });
  }

  async delData(id, makePassive, rowIndex) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("opportunityId", id);
    urlencoded.append("makePassive", makePassive ? 'true' : 'false');

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/opportunity/del-opportunity',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  async makeActive(productId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("opportunityId", productId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/opportunity/activate-opportunity',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  refreshList = () => {
    this.loadData().then();
  }

  excelExport = () => {
    const params = {
      fileName: "Firsat-Listesi.xlsx",
      sheetName: 'Firsat-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.gridSettingJson)
      this.gridColumnApi.setColumnState(JSON.parse(this.gridSettingJson));
  };

  saveGridSetting = () => {
    localStorage.removeItem('opportunityGridSetting');
    let savedState = this.gridColumnApi.getColumnState();
    localStorage.setItem('opportunityGridSetting', JSON.stringify(savedState));
    Mesaj.show('Tablo ayarları kayıt edildi!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  onFirstDataRendered = params => {
    if (!this.gridSettingJson)
      params.api.sizeColumnsToFit();
  };

  prepareRowData(list) {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  addEditProduct = async () => {
    if (!this.state.formData.title.value) {
      Mesaj.show('Başlık boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.category.value && !this.state.formData.brand.value && !this.state.formData.product.value) {
      Mesaj.show('Kategori, marka veya üründen herhangi biri seçilmelidir. ', 'Uyarı', Mesaj.ICON_WARN,
        Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.editData && !this.state.formData.imageFileName.value) {
      Mesaj.show('Banner boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    let titleExist = false;
    const that = this;

    this.gridApi.forEachNode(function (rowNode, index) {
      if (!that.state.editData &&
        rowNode.data.title.toString().toLocaleLowerCase('tr') ===
        that.state.formData.title.value.toLocaleLowerCase('tr')) {
        titleExist = true;
        return;
      }
    });

    if (titleExist) {
      Mesaj.show('Bu isimde bir fırsat daha önceden oluşturulmuş', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({loading: true});

    const data = new FormData();

    if (this.state.editData)
      data.append('opportunityId', this.state.editData.opportunityId);

    data.append('title', this.state.formData.title.value);
    data.append('description', this.state.formData.description.value);
    data.append('image', this.state.formData.imageFileName.value);
    data.append('showSlider', this.state.formData.showSlider.value);
    data.append('categoryId', this.state.formData.category.value);
    data.append('productId', this.state.formData.product.value);
    data.append('brand', this.state.formData.brand.value);
    data.append('priority', this.state.formData.priority.value);
    data.append('opportunityProjectId', this.state.formData.projectId.value || 1);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken()
    }

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/opportunity/add-edit-opportunity',
      'POST', headers, data);

    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.clearEditMode();
    Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Fırsat düzenleme başarılı', 'Bilgi',
      Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then(() => {
      this.gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.opportunityId === apiRes.opportunityId) {
          rowNode.setSelected(true);
          that.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    });
  }

  clearEditMode() {
    this.state.formData.title.value = '';
    this.state.formData.description.value = '';
    this.state.formData.imageFileName.value = '';
    this.state.formData.showSlider.value = 'false';
    this.state.formData.priority.value = 0;
    this.state.formData.category = {};
    this.state.formData.product = {};
    this.state.formData.currImage.value = null;
    this.setState({editData: null});
  }

  editProduct = editData => {
    this.state.formData.title.value = editData.title;
    this.state.formData.description.value = editData.description;
    this.state.formData.imageFileName.value = null;
    this.state.formData.showSlider.value = editData.showSlider;
    this.state.formData.priority.value = editData.priority;
    this.state.formData.currImage.value = editData.imageFileName;
    this.state.formData.category = {value: editData.categoryId ? editData.categoryId : 0, label: editData.categoryName};
    this.state.formData.product = {value: editData.productId ? editData.productId : 0, label: editData.productName};
    this.setState({editData: editData});
  }

  getContextMenuItems = params => {
    if (params.node.group === true)
      return null;

    const menu = [
      {
        name: 'Fırsat Sil',
        action: this.askForDeleteRow.bind(this, false),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Fırsatı Pasif Yap',
        disabled: params.node.data.deleted,
        action: this.askForDeleteRow.bind(this, true),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Fırsatı Aktifleştir',
        disabled: !params.node.data.deleted,
        action: this.makeActive.bind(this, params.node.data.opportunityId)
      },
      {
        name: 'Fırsatı Düzenle',
        action: this.editProduct.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      }
    ];
    return menu;
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';

    return null;
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer} style={{display: 'flex', flexWrap: 'wrap'}}>

        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}
        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />
        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.addEditProduct.bind(this)}
          >
            {this.state.editData ? 'Fırsat Bilgi Güncelle' : 'Fırsat Ekle'}
          </Button>
        </div>

        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            size="small"
            color="primary"
            startIcon={<CancelIcon/>}
            onClick={this.clearEditMode.bind(this)}
          >
            Düzenleme Modundan Çık
          </Button>
        </div>

      </div>

      <div>
        <Toolbar handlers={
          [
            {callBack: this.refreshList, title: 'Yenile', icon: '/images/menu/mnuAutorenew_24px.svg'},
            {callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'},
            {
              callBack: this.saveGridSetting.bind(this),
              title: 'Tablo Ayarlarını Kaydet',
              icon: '/images/menu/24px/mnuSaveSettings.png'
            }
          ]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 295px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          getContextMenuItems={this.getContextMenuItems}
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          getRowClass={this.getRowClass}
        >
        </Grid>
      </div>
    </div>
  };
}

export default Opportunity;
