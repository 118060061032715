import React, {useEffect, useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {makeStyles} from '@material-ui/styles';
import {Button, Checkbox, FormHelperText, Grid, Link, TextField, Typography} from '@material-ui/core';
import Util from "../../utils/CommonUtil";
import ProductChoicesTab from "../../components/ProductChoicesTab";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import {green} from "@material-ui/core/colors";
import Mesaj from "../../utils/Mesaj";
import {Consts} from "../../const/Consts";

const schema = {
  name: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 64
    }
  },
  email: {
    presence: {allowEmpty: false, message: 'is required'},
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: {allowEmpty: false, message: 'is required'},
    checked: true
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '90%',
    width:"100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/robot2.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const SignUp = props => {
  //const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [loading, setLoading] = React.useState(false);

  const [showDialog, setShowDialog] = React.useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  // const handleBack = () => {
  //   history.goBack();
  // };


  function parseName(name) {
    if (!name.includes(" ")) {
      return [name, ''];
    }

    let firstName = name.substring(0, name.lastIndexOf(" "));
    let lastName = name.substring(name.lastIndexOf(" ") + 1);
    return [firstName, lastName];
  }

  const handleSignUp = async event => {
    event.preventDefault();
    setLoading(true);

    let headers = {
      'Content-Type': 'application/json'
    };

    let body = {
      name: formState.values.name,
      lastname: formState.values.lastname,
      email: formState.values.email,
      password: formState.values.password
    };
    let createUserResponse = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/sign-up', 'POST', headers, JSON.stringify(body));
    setLoading(false);

    console.log('SignUp Response', createUserResponse);
    if (createUserResponse.result !== Consts.RESULT_SUCCESS) {
      Mesaj.show(createUserResponse.resultMessage || 'Hesap açılışında bir hata oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    setShowDialog(true);

    //Direk login iptal edildi, aktivasyon sonrası, login işlemi gerçekleşecektir.
    /*let result= await LoginUtil.login(domainType,domainCode,formState.values.email, formState.values.password);

    if(result === true) {
      window.location.href= "/";
    } else
    {
      alert('An error occured:'+ JSON.stringify(result));
    }
    */

  };


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  function closeDialog() {
    setShowDialog(false);
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}
                 style={{visibility: 'hidden'}}
            >
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                Skyland Kartlı Geçiş Web Portal
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >
                  Slogan Buraya
                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                  XYZ A.Ş.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            {/*<div className={classes.contentHeader}>*/}
            {/*  <IconButton onClick={handleBack}>*/}
            {/*    <ArrowBackIcon />*/}
            {/*  </IconButton>*/}
            {/*</div>*/}
            <ProductChoicesTab tabId={1} setTabId={null}/>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Hesap Oluşturun
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  E-posta adresiniz ile kolayca hesap açabilirsiniz.
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('firstName')}
                  fullWidth
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  label="İsim"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('lastname')}
                  fullWidth
                  helperText={
                    hasError('lastname') ? formState.errors.firstName[0] : null
                  }
                  label="Soyad"
                  name="lastname"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastname || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="E-posta"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Cep Telefonu (Başında 0 olmadan)"
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phone || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Şifre"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {' '} <Link
                    color="primary"
                    component={RouterLink}
                    to="#"
                    underline="always"
                    variant="h6"
                  >
                    Kullanım Koşullarını
                  </Link> okudum ve kabul ediyorum.
                  </Typography>
                </div>
                {hasError('policy') && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid || loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading ? ' LÜTFEN BEKLEYİNİZ ' : 'HESAP OLUŞTUR'}
                  {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Zaten bir hesabım var. {' '}
                  <Link
                    component={RouterLink}
                    to="/sign-in"
                    variant="h6"
                  >
                    Giriş
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>

      <Dialog open={showDialog === true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Bilgi</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>E-posta: {formState.values.email}</strong>
          </DialogContentText>
          <DialogContentText>
            Hesabınız başarı ile açılmıştır. <Link
            component={RouterLink}
            to="/sign-in"
            variant="h6"
          >
            Giriş için tıklayınız.
          </Link>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()} color="primary">
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
