import React, { Component } from 'react';
import Saha from '../../../assets/RobotRemoteImages/saha.png';
import NoImage from '../../../assets/RobotRemoteImages/no_image.jpg';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/styles';
import { Consts } from 'const/Consts';
const RobotCamera = props => {
  const classes = useStyles();
  const cameraSettings = props.cameraSettings;

  const enableChange = () => {
    if (props.hasPermission) {
      props.setCameraSettings({
        ...cameraSettings,
        isEnable: !cameraSettings.isEnable
      });
    }
  };
  return (
    <div style={{ textAlign: 'center', position: 'relative' }}>
      <Switch
        checked={cameraSettings.isEnable}
        onChange={enableChange}
        classes={{
          root: classes.rootLeft,
          track: classes.track
        }}
        color="secondary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {/* TODO If there are 2 cameras */}
      {/* {cameraSettings.isEnable ? (
        <Switch
          checked={cameraSettings.isOpenFront}
          onChange={() =>
            props.setCameraSettings({
              ...cameraSettings,
              isOpenFront: !cameraSettings.isOpenFront
            })
          }
          classes={{
            root: classes.rootRight,
            track: classes.track
          }}
          color="secondary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      ) : null} */}

      <img
        style={{ border: '1px solid black', borderRadius: '10px' }}
        src={cameraSettings.isEnable ? 
          props.src == 'data:image/jpg;base64,undefined' ? NoImage
            : props.src == 'data:image/jpg;base64,' ? NoImage
              : props.src == 'data:image/jpg;base64,null' ? NoImage
                : props.src
          : Saha}
      />
    </div>
  );
};
const useStyles = makeStyles({
  rootRight: {
    position: 'absolute',
    right: '25px',
    top: '-8px'
  },
  rootLeft: {
    position: 'absolute',
    left: '17px',
    top: '-8px'
  },
  track: {
    opacity: 1,
    backgroundColor: '#fff',
    border: `1px solid grey`
  }
});
export default RobotCamera;
