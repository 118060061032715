import React, { Component } from 'react';
import controlContainerStyle from '../Styles/ControlContainer.module.css';
import stateControlStyle from '../Styles/StateWithIcon.module.css';

const StateWithIcon = props => {
  return (
    <div className={controlContainerStyle.container}>
      <div
        style={{
          textAlign: 'center',
          flex: '3',
          maxHeight: '124.5px',
          marginTop: props.marginTop
        }}>
        <img width="80px" src={props.icon} />
      </div>
      <div className={stateControlStyle.value} style={{ textAlign: 'center' }}>
        <p>{props.value || 'n/a'}</p>
      </div>
    </div>
  );
};
export default StateWithIcon;
