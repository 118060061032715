import React, {Component} from "react";
import {AllModules} from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import {AgGridReact} from "@ag-grid-community/react";
import Util from "../../utils/CommonUtil";

class PopGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: false
      },
      getRowNodeId: function (item) {
        return item.id;
      },
      components: {
        loadingCellRenderer: function (params) {
          if (params.value !== undefined) {
            return params.value;
          } else {
            return '<img src="https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/images/loading.gif">';
          }
        }
      }
    };

    this.gridOptions = {
      onRowDoubleClicked: this.props.onSelection
    }

    this.props.currentComponentSetter(this);

  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (Util.isTrue(this.props.lazy)) {
      params.api.setDatasource(this.props.dataSource);
    }

    this.props.gridApiSetter(params.api, params.columnApi);

  };

  /* İptal edildi. Bu işlem parent comp içine alındı.
  onRowDoubleClicked() {
    let selectedRow = this.gridApi.getSelectedRows();
    this.props.onSelection(this.props.popModalProps,selectedRow);
  }*/

  cellFocused = (params) => {

    params.api.forEachNode(function (rowNode, index) {
      if (index !== params.rowIndex) {
        rowNode.setSelected(false);
      } else {
        rowNode.setSelected(true);
      }

    });
  }


  render() {
    let lazyGridProps;
    let nonLazyGridProps;

    if (Util.isTrue(this.props.lazy)) {

      //maxBlocksInCache: paginationPageSize değerinden düşük olursa sonsuz sorgu döngüsü oluyor.
      lazyGridProps = {
        rowModelType: 'infinite',
        paginationPageSize: 50,
        cacheBlockSize: 50,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        infiniteInitialRowCount: 1,
        maxBlocksInCache: 100
      }

      // lazyGridProps= {
      //   rowModelType: "serverSide",
      //   cacheBlockSize: 100,
      //   maxBlocksInCache: 10
      // }
    } else {
      nonLazyGridProps = {
        rowData: this.props.rowData
      }
    }
    return (
      <div
        id="pop-grid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          modules={this.state.modules}
          columnDefs={this.props.columnDefs}
          debug={false}
          defaultColDef={this.state.defaultColDef}
          rowSelection='single'
          rowDeselection={true}
          {...lazyGridProps}
          {...nonLazyGridProps}
          // getRowNodeId={this.state.getRowNodeId}
          components={this.state.components}
          onGridReady={this.onGridReady}
          gridOptions={this.gridOptions}
          onCellFocused={this.cellFocused}
        />
      </div>
    );
  }
}


export default PopGrid;
