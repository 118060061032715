import React, { Component } from 'react';
import styles from './ImageToolTip.module.css'

export default class ImageToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOver:false,
    };
  }

  render()  {
    console.log(this.props.value);

    return <div className={styles.toolTip}>
    <img
      src={process.env.REACT_APP_API_GATEWAY_URL + '/images/'+ this.props.value}/>
    </div>
  }
}
