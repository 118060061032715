import React, {Component} from 'react';
import FormService from '../../services/Form/FormService';
import FormEventService from "../../services/Form/FormEventService";
import Util from "../../utils/CommonUtil";
import Mesaj from "../../utils/Mesaj";


class InputForm extends Component {

  constructor(props) {
    //this.params yerine this.props.inputParams
    super(props);
  }

  componentDidMount() {
    FormEventService.focusForInitialLoad(this);
  }

  onKeyDown = (event) => {
    FormEventService.onKeyDown(event, this);
  }

  handleFormChange = (event) => {
    FormService.handleFormChange(event, this);
  }

  render() {
    return (
      <div onKeyDown={this.onKeyDown}>
        <div
          className="form-main-container"
          style={{
            fontSize: '13px',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {
            Object.keys(this.props.inputParams).map((key, index) => {
              return FormService.prepareItem(this, key, this.props.inputParams[key], index);
            })
          }
        </div>
      </div>
    )
  }
}

export default InputForm;

