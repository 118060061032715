import React, {useEffect, useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {makeStyles} from '@material-ui/styles';
import {Button, Grid, Link, TextField, Typography} from '@material-ui/core';
import LoginUtil from "../../utils/LoginUtil";
import ProductChoicesTab from "../../components/ProductChoicesTab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {green} from "@material-ui/core/colors";
import Util from "../../utils/CommonUtil";
import Mesaj from "../../utils/Mesaj";
import store from "../../redux/store/store";
import {
  CHECK_PAGES
} from "../../redux/actions/actionTypes";
import Dialog from "@material-ui/core/Dialog";

//import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

const schema = {

  // productNo: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 10
  //   }
  // },
  phone: {
    presence: {allowEmpty: false, message: 'is required'},
    numericality: true,
    length: {
      maximum: 10,
      minimum: 10
    }
  },
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '90%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/robot2.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const SignIn = props => {
  //const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  //st: state kisaltma
  const [st, setSt] = React.useState({
    loading: false
  });

  const [showSmsCodeDialog, setShowSmsCodeDialog] = React.useState();
  const [smsCode, setSmsCode] = React.useState();

  const [tabId, setTabId] = React.useState(1);
  const handleTabChange = (event, newTabId) => {
    setTabId(newTabId);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  // const handleBack = () => {
  //   history.goBack();
  // };

  const handleSmsCode = event => {
    setSmsCode(event.target.value);
  }

  const handleChange = event => {
    event.persist();

    setSt({loading: false});

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = async event => {
    setSt({loading: true});
    let result = await LoginUtil.login(formState.values.phone, formState.values.password, smsCode);
    setSt({loading: false});

    if (result !== true && result.result !== Util.APIRES_SUCCESS) {
      Mesaj.show(result.resultMessage, 'Hata', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    setShowSmsCodeDialog(false);

    store.dispatch(
      {
        type: CHECK_PAGES
      });

    const redirect = new URLSearchParams(props.location.search).get("redirect")
    if(redirect)
      window.location.href = "/" + redirect;
    else
      window.location.href = "/";
    return;
  };

  const sendSmsCode = async event => {
    event.preventDefault();
    setSt({loading: true});
    let result = await LoginUtil.sendSmsCode(formState.values.phone, formState.values.password, smsCode);
    setSt({loading: false});

    if (result !== true && result.result !== Util.APIRES_SUCCESS) {
      Mesaj.show(result.resultMessage, 'Hata', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    setShowSmsCodeDialog(true);
    return;
  };

  const hasError = field => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  }

  return (
    <div className={classes.root}>

      <Dialog
        open={showSmsCodeDialog}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <div style={{padding: '20px'}}>
          <TextField
            className={classes.textField}
            label="Sms Kodu Giriniz."
            name="smsCode"
            onChange={handleSmsCode}
            type="text"
            value={smsCode}
            variant="outlined"
            fullWidth={true}
            autoFocus={true}
          />
          <br/>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formState.isValid || st.loading}
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSignIn}
            fullWidth
          >
            Giriş Yap
          </Button>
        </div>
      </Dialog>

      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}
                 style={{visibility: 'hidden'}}
            >
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                Skyland Kartlı Sistem Web Portal
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >
                  Slogan Buraya
                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                  XYZ A.Ş.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            {/*<div className={classes.contentHeader}>*/}
            {/*  <IconButton onClick={handleBack}>*/}
            {/*    <ArrowBackIcon />*/}
            {/*  </IconButton>*/}
            {/*</div>*/}
            {/* <ProductChoicesTab render={!props.match.params.product} tabId={tabId}
                               setTabId={handleTabChange}/> */}
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={sendSmsCode}
              >

                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Giriş
                </Typography>

                {/*Sosyal Medya ile login şimdilik devre dışı<Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Sign in with social media
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      onClick={handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      <FacebookIcon className={classes.socialIcon} />
                      Login with Facebook
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      <GoogleIcon className={classes.socialIcon} />
                      Login with Google
                    </Button>
                  </Grid>
                </Grid>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  or login with email address
                </Typography>

                */}
                <TextField
                  className={classes.textField}
                  error={hasError('phone')}
                  fullWidth
                  helperText={
                    hasError('phone') ? formState.errors.phone[0] : null
                  }
                  label="Telefon(Başında 0 olmadan)"
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phone || ''}
                  variant="outlined"
                  autoComplete="username"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Şifre"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                  autoComplete="current-password"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid || st.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {st.loading ? ' LÜTFEN BEKLEYİNİZ ' : 'SMS KODU AL'}
                  {st.loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                </Button>
                {/*<Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Hesabınız yok mu?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    variant="h6"
                  >
                    Üye ol
                  </Link>
                </Typography>*/}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
