import React, {Component} from 'react';
import styles from './MostSearchedWords.module.css'
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import Dialog from "@material-ui/core/Dialog";
import WordDetail from "./WordDetail";
import FormService from "../../services/Form/FormService";
import InputForm from "../InputForm/InputForm";
import Button from "@material-ui/core/Button";

class MostSearchedWords extends Component {
  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true
      },
      {
        headerName: 'Aranan Kelime',
        field: 'searchedWord'
      },
      {
        headerName: 'Aranma Adeti',
        filter: 'agNumberColumnFilter',
        field: 'count'
      }
    ];

    this.inputParams = {
      beginDate: {
        label: 'Başlangıç Tarihi',
        defaultValue: '',
        type: 'datetime-custom',
        dateType: 'date',
        nullable: true
      },
      finishDate: {
        label: 'Bitiş Tarihi',
        defaultValue: '',
        type: 'datetime-custom',
        dateType: 'date',
        nullable: true
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    };

    this.state = {
      loading: false,
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      showWordDetailModal: false,
      selectedWord: null
    };
  }

  loadData = async () => {
    this.setState({loading: true});

    const form = this.state.formData;
    const body = {
      beginDate: form.beginDate.value,
      finishDate: form.finishDate.value,
      projectId: form.projectId.value
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL +
      '/most-searched-words/get-most-searched-word', 'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      gridRowData: this.prepareRowData(apiRes)
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();
  }

  excelExport = () => {
    const params = {
      fileName: "En-Cok-Aranan-Kelimeler-Listesi.xlsx",
      sheetName: 'En-Cok-Aranan-Kelimeler-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  closeModal = () => {
    this.setState({showWordDetailModal: false});
  }

  onRowDoubleClicked = (event) => {
    this.setState({
      showWordDetailModal: true,
      selectedWord: event.data
    });
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  render() {
    return <div className={styles.mainContainer}>
      {this.state.loading &&
      <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9999}}/>}

      <Dialog
        open={this.state.showWordDetailModal}
        onClose={this.closeModal}
        fullWidth={true}
        maxWidth={'md'}
      >
        <WordDetail
          onClose={this.closeModal}
          selectedWord={this.state.selectedWord}
          beginDate={this.state.formData.beginDate.value}
          finishDate={this.state.formData.finishDate.value}
          projectId={this.state.formData.projectId.value}
        />
      </Dialog>

      <InputForm
        formData={this.state.formData}
        formDataUpdater={this.formDataUpdater}
        inputParams={this.inputParams}
        inputFormColumnWidth={'50%'}
        tabLoop={true}
        customTabKeyCode={13}
      />

      <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
        <Button
          disabled={this.state.loading}
          variant={"contained"}
          color="primary"
          size="small"
          onClick={this.loadData}
        >
          Sorgula
        </Button>
      </div>

      <div>
        <Toolbar handlers={
          [{callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'}]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 160px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          suppressContextMenu={true}
          onRowDoubleClicked={this.onRowDoubleClicked}
        >
        </Grid>
      </div>
    </div>
  };
}

export default MostSearchedWords;
