import { Account as AccountView, Settings as SettingsView } from '../views';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import EmptyPageComponent from './EmptyPageComponent';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StorageIcon from '@material-ui/icons/Storage';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Dashboard from '../views/Dashboard';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CategoryIcon from '@material-ui/icons/Category';
import Kategori from "../components/Kategori/Kategori";
import LoginUtil from "./LoginUtil";
import {Consts} from "../const/Consts";
import Product from "../components/Product/Product";
import Order from "../components/Order/Order";
import React from "react";
import Menu from "../components/Menu/Menu";
import Opportunity from "../components/Opportunity/Opportunity";
import User from "../components/User/User";
import MostSearchedWords from "../components/MostSearchedWords/MostSearchedWords";
import SalesSummary from "../components/SalesSummary/SalesSummary";
import ServerStatus from "../components/ServerStatus/ServerStatus";
import Coupon from "../components/Coupon/Coupon";
import RobotRemoteProject from "../components/RobotRemoteProject/index";
import history from '../history';

export const PageAndMenuUtil = {
  tabFaturaComponenet: {
    tabComponent: 'Fatura',
    title: 'Fatura',
    icon: 'ReceiptIcon',
    multiTab: true,
    exitConfirm: true
  },
  defaultVisiblePages: [
    { tabComponent: 'AccountView', title: 'Profil', icon: 'AccountBoxIcon' },
    { tabComponent: 'SettingsView', title: 'Ayarlar', icon: 'SettingsIcon' }
  ],

  showChangeCompanyAndPeriodDialog: false,
  getComponentByName: function getComponentByName(compName, props) {
    if (compName === 'AccountView') {
      return <AccountView />;
    } else if (compName === 'SettingsView') {
      return <SettingsView />;
    } else if (compName === 'EmptyPageComponent') {
      return <EmptyPageComponent />;
    } else if (compName === 'Dashboard') {
      return <Dashboard {...props} />;
    } else if (compName === 'Kategori') {
      return <Kategori {...props} />;
    } else if (compName === 'Product') {
      return <Product {...props} />;
    } else if (compName === 'Opportunity') {
      return <Opportunity {...props} />;
    } else if (compName === 'Order') {
      return <Order {...props} />;
    } else if (compName === 'Menu') {
      return <Menu {...props} />;
    } else if (compName === 'Coupon') {
      return <Coupon {...props} />;
    } else if (compName === 'User') {
      return <User {...props} />;
    } else if (compName === 'MostSearchedWords') {
      return <MostSearchedWords {...props} />;
    } else if (compName === 'SalesSummary') {
      return <SalesSummary {...props} />;
    } else if (compName === 'ServerStatus') {
      return <ServerStatus {...props} />;
    } else if (compName === 'ElevatorCall') {
      window.location.href = '/elevator';
      return;
    } else if (compName === 'ElevatorSecureCall') {
      window.location.href = '/elevator-secure';
      return;
    } else if (compName === "RobotRemoteProject") {
      console.log('history push');
      history.push('/robot-remote');
      return;
    }
  },

  getIconComponentByName: function getIconComponentByName(iconCompName) {
    if (iconCompName === 'AccountBoxIcon') {
      return <AccountBoxIcon />;
    } else if (iconCompName === 'SettingsIcon') {
      return <SettingsIcon />;
    } else if (iconCompName === 'SwapHorizontalCircleIcon') {
      return <SwapHorizontalCircleIcon />;
    } else if (iconCompName === 'ApartmentIcon') {
      return <ApartmentIcon />;
    } else if (iconCompName === 'BusinessIcon') {
      return <BusinessIcon />;
    } else if (iconCompName === 'CollectionsBookmarkIcon') {
      return <CollectionsBookmarkIcon />;
    } else if (iconCompName === 'ImportContactsIcon') {
      return <ImportContactsIcon />;
    } else if (iconCompName === 'PeopleIcon') {
      return <PeopleIcon />;
    } else if (iconCompName === 'StorageIcon') {
      return <StorageIcon />;
    } else if (iconCompName === 'ReceiptIcon') {
      return <ReceiptIcon />;
    } else if (iconCompName === 'MonetizationOnIcon') {
      return <MonetizationOnIcon />;
    } else if (iconCompName === 'AccountBalanceWalletIcon') {
      return <AccountBalanceWalletIcon />;
    } else if (iconCompName === 'CategoryIcon') {
      return <CategoryIcon />;
    }
  },

  getSpeedyPages() {
    const pages = [];

    const profile = LoginUtil.getProfile();
    if (profile.role === Consts.ROLE_ELEVATOR_USER) {
      pages.push({
        tabComponent: 'ElevatorCall',
        title: 'Asansör Çağır',
        icon: 'CategoryIcon'
      });

      return pages;
    }

    if (profile.role === Consts.ROLE_ADMIN || profile.role === Consts.ROLE_MODERATOR) {
      pages.push({
        tabComponent: 'Product',
        title: 'Ürün İşlemleri',
        icon: 'CategoryIcon',
        defaultOpen: true,
        multiTab: false
      });
      pages.push({
        tabComponent: 'Kategori',
        title: 'Kategori İşlemleri',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'Menu',
        title: 'Menü İşlemleri',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'Opportunity',
        title: 'Fırsatlar İşlemleri',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'Order',
        title: 'Siparişler',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'Coupon',
        title: 'Kupon',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'User',
        title: 'Kullanıcılar',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'MostSearchedWords',
        title: 'En Çok Aranan Kelimeler',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'SalesSummary',
        title: 'Satış Özeti',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'ServerStatus',
        title: 'Sunucu Durumu',
        icon: 'CategoryIcon',
        multiTab: false
      });
      pages.push({
        tabComponent: 'ElevatorCall',
        title: 'Asansör Çağır',
        icon: 'CategoryIcon'
      });
      pages.push({
        tabComponent: 'EmptyPageComponent',
        title: 'Tanımlar',
        icon: 'ImportContactsIcon'
      });
      pages.push({
        title: 'Robot Remote',
        icon: 'ImportContactsIcon',
        href: '/robot-remote'
      });
    }

    pages.push({
      tabComponent: 'Dashboard',
      title: 'Panel',
      icon: 'ImportContactsIcon'
    });

    return pages;
  },

  getPageDefinitions(selectedPeriod) {
    let profileJsonAsStr = sessionStorage.getItem('profile');
    if (!profileJsonAsStr) {
      return null;
    }

    const storedProfile = JSON.parse(profileJsonAsStr);

    let pageList = [];

    //TODO ilerde farklı tür giriş olursa ilgili sayfa tipleri buraya gelecek.
    pageList = PageAndMenuUtil.getSpeedyPages();

    return pageList.concat(PageAndMenuUtil.defaultVisiblePages);
  }
};
