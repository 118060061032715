import React, {Component} from 'react';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "../Grid/Grid";

class WordDetail extends Component {
  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Arama Tarihi',
        field: 'searchDate',
        cellRenderer: 'DateTimeCellRenderer',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          debounceMs: 500,
          comparator: Util.GRID_DATE_COMPARATOR
        },
        cellRendererParams: {
          format: 'DD.MM.YYYY HH:mm'
        }
      }
    ];

    this.state = {
      gridColumnDefs: [],
      gridRowData: []
    };
  }

  async componentDidMount() {
    this.getOrderItems().then();
  }

  getOrderItems = async () => {
    this.setState({loading: true});

    const body = {
      searchWord: this.props.selectedWord.searchedWord,
      beginDate: this.props.beginDate,
      finishDate: this.props.finishDate,
      projectId: this.props.projectId
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/most-searched-words/get-word-detail',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      gridRowData: this.prepareRowData(apiRes)
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  render() {
    return <div style={{padding: '10px'}}>
      <div style={{display: 'flex', flexWrap: 'wrap', height: '350px'}}>
        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}
        <div
          style={{
            height: '300px',
            width: '100%',
            marginTop: '8px',
            zIndex: '0'
          }}
        >
          <Grid
            columnDefs={this.colDefs}
            defaultColDef={this.defaultColDef}
            rowData={this.state.gridRowData}
            rowSelection='single'
            suppressContextMenu={true}
            onGridReadyCustom={this.onGridReady}
            onFirstDataRendered={this.onFirstDataRendered}
            summaryBar={false}
          >
          </Grid>
        </div>
      </div>
    </div>
  };
}

export default WordDetail;
