import React, {Component} from 'react';

class ModalSub extends Component {

  render() {
    return (
      <div>
        <span>{this.props.loading}</span>
        {this.props.children}
      </div>
    );
  }

}

export default ModalSub;
