import LocalizedStrings from 'react-localization';

export const rrLanguage = new LocalizedStrings({
  en: {
    open: 'Open',
    close: 'Close',
    exit:'Exit',
    robotSiteFloor: 'Robot Site / Floor',
    state: 'State',
    targetSiteFloor: 'Target Site / Floor',
    manualController: 'Manuel Controller',
    eStop: 'Estop',
    autonomy: 'Autonomy',
    navigation: 'Navigation',
    elevator: 'Elevator',
    control: 'Control',
    Error: 'Error!',
    Recovery: 'Auto Recovery...',
    GoingToTargetFloor: 'Going to Target Floor',
    EnteringElevator: 'Entering Elevator',
    WaitingForElevator: 'Waiting Elevator',
    GoingForElevator: 'Going for Elevator',
    ReadyForFilling: 'Ready for Filling',
    GoingForTheQueue: 'Going for the Queue',
    ReadyForMission: 'Ready for Mission',
    Initialize: 'Preparing',
    TargetFloor: 'Target Floor',
    FatalError: 'Fatal Error!',
    leftTopLid: 'Left Top Lid',
    rightTopLid: 'Right Top Lid',
    leftBottomLid: 'Left Bottom Lid',
    rightBottomLid: 'Right Bottom Lid',
    robotCall:'Robot Call',
    normal:"Normal (0.25)",
    slow:"Slow (0.1)",
    disableManualControl:"Disable Manual Control",
    enableManualControl:"Enable Manual Control",
    robotHistory:"Robot History",
  },
  tr: {
    open: 'Aç',
    close: 'Kapat',
    exit:'Çıkış',
    robotSiteFloor: 'Robot Site / Kat',
    state: 'Durum',
    targetSiteFloor: 'Hedef Site / Kat',
    manualController: 'Manuel Sürüş',
    eStop: 'Acil Stop',
    autonomy: 'Otonomi',
    navigation: 'Navigasyon',
    elevator: 'Asansör',
    control: 'Kontrol',
    Error: 'Hata!',
    Recovery: 'Oto Kurtarma...',
    GoingToTargetFloor: 'Hedef kata gidiyor.',
    EnteringElevator: 'Asansöre biniyor.',
    WaitingForElevator: 'Asansörü bekliyor.',
    GoingForElevator: 'Asansöre gidiyor.',
    ReadyForFilling: 'Yüklemeye hazır.',
    GoingForTheQueue: 'Kuyruğa gidiyor.',
    ReadyForMission: 'Hazır.',
    Initialize: 'Hazırlanıyor.',
    TargetFloor: 'Hedef katta.',
    FatalError: 'Önemli Hata!',
    leftTopLid: 'Sol Üst Kapak',
    rightTopLid: 'Sağ Üst Kapak',
    leftBottomLid: 'Sol Alt Kapak',
    rightBottomLid: 'Sağ Alt Kapak',
    robotCall:'Robot Çağır',
    normal:"Normal (0.25)",
    slow:"Yavaş (0.1)",
    disableManualControl:"Manuel Kontrolü Devre Dışı Bırak",
    enableManualControl:"Manuel Kontrolü Etkinleştir",
    robotHistory:"Robot Geçmişi",
  }
});
