/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, colors, List, ListItem } from '@material-ui/core';
import { PageAndMenuUtil } from '../../../../../../utils/PageAndMenuUtil';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

// const CustomRouterLink = forwardRef((props, ref) => (
//   <div
//     ref={ref}
//     style={{ flexGrow: 1 }}
//   >
//     <RouterLink {...props} />
//   </div>
// ));

const SidebarNav = props => {
  const { pages, className } = props;

  const classes = useStyles();

  return (
    <div>
      <List className={clsx(classes.root, className)}>
        {pages.map((page, index) => (
          <ListItem className={classes.item} disableGutters key={index}>
            <Button
              //activeClassName={classes.active}
              className={[
                classes.button,
                page.active ? classes.active : null
              ].join(' ')}
              //component={CustomRouterLink}
              to={page.href}
              href={page.href}
              //onClick={ () =>  (page.reduxAction ? altosStore.dispatch(page.reduxAction) : props.updateMainComponent(page,pages) )  }
              onClick={() => props.handleMenuButtonClick(page)}>
              <div className={classes.icon}>
                {PageAndMenuUtil.getIconComponentByName(page.icon)}
              </div>
              {page.title}
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
