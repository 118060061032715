import React, {Component} from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AppBar from "@material-ui/core/AppBar";
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';

class ProductChoicesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {}

  }

  a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  prepareTabOptions() {
    const tabOptions=[];
    tabOptions.push(<Tab label="SPEEDY MARKET" value={1} icon={<AccountBalanceIcon/>} {...this.a11yProps(1)}
           title="Hızlı ve Kolay Sipariş."/>);

    return tabOptions;
  }

  render() {

    if (this.props.render === false) {
      return null;
    }
    return (
      <AppBar position="static" color="default">
        <Tabs
          value={this.props.tabId}
          onChange={this.props.setTabId}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {this.prepareTabOptions()}
          {/*<Tab label="ALTOS TİCARİ" value={3} icon={<AccountBalanceIcon />} {...this.a11yProps(3)} title="Firmanız için ön muhasebe ve genel muhasebe seti" />*/}
        </Tabs>
      </AppBar>
    );
  }


}

export default ProductChoicesTab;
