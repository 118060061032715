import React, {Component} from 'react';
import styles from "./Modal.module.css"


function dragElement(toBeDraggedDiv, dragHandleHtml) {
  let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  dragHandleHtml.onmousedown = dragMouseDown;
  // if (document.getElementById(modal.id + "header")) {
  //   /* if present, the header is where you move the DIV from:*/
  //   document.getElementById(modal.id + "header").onmousedown = dragMouseDown;
  // } else {
  //   /* otherwise, move the DIV from anywhere inside the DIV:*/
  //   modal.onmousedown = dragMouseDown;
  // }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    toBeDraggedDiv.style.top = (toBeDraggedDiv.offsetTop - pos2) + "px";
    toBeDraggedDiv.style.left = (toBeDraggedDiv.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}


class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.headerRef = React.createRef();

    this.state = {}

    this.header = null;
    this.body = null;
    this.footer = null;

    this.top = props.top ? props.top : (props.height ? 'calc(50% - calc(' + props.height + ' / 2) )' : '25%');
    this.left = props.left ? props.left : "25%";
    this.width = props.width ? props.width : "50%";
    this.height = props.height ? props.height : "350px";

    this.mainDivRef = React.createRef();
    this.keyEventCallbackMap = new Map(this.props.keyEventCallbackMap);
    //ESC tuşu da ekleniyor.
    this.keyEventCallbackMap.set(27, this.props.closeHandler);
  }

  prepareChildren() {

    let childArray = [];

    if (Array.isArray(this.props.children)) {
      childArray = this.props.children;
    } else {
      childArray[0] = this.props.children;
    }

    let th = this;
    childArray.forEach(function (child) {
      if (child.type.displayName) {
        if (child.type.displayName === "ModalHeader") {
          th.header = child;
        } else if (child.type.displayName === "ModalBody") {
          th.body = child;
        } else if (child.type.displayName === "ModalFooter") {
          th.footer = child;
        } else {
          console.error("Disallowed Modal Child Item:");
          console.error(child);
        }
      }

    });


  }

  componentDidMount() {
    dragElement(this.modalRef.current, this.headerRef.current);
    this.mainDivRef.current.addEventListener("keydown", this.onModalKeyDownCapturing, true);

  }


  prepareStyles() {
    this.styles = {
      zIndex: this.props.zIndex,
      display: this.props.open ? 'block' : 'none'
    }
  }

  /* Ag-grid'in event mekanizması Bubling yönteminde ilk çalıştığı için, burada Capturing yöntemiyle daha önce çalışması sağlanıyor. */
  onModalKeyDownCapturing = (event) => {
    const keyCode = event.which || event.keyCode;

    console.log('onModalKeyDownCapturing KeyCode:' + keyCode + ' event.eventPhase' + event.eventPhase);
    if (this.keyEventCallbackMap.has(keyCode)) {


      let parent = event.target;
      for (let i = 0; i < 30; i++) {
        if (!parent)
          return;

        //Seperator bulunmuşsa, daha yukarı gitmemelidir.
        if (parent.hasAttribute("seperator") && parent.getAttribute("seperator") === 'mainModalDiv') {
          if (parent === event.currentTarget) {
            event.stopPropagation();
            const eventCallback = this.keyEventCallbackMap.get(keyCode);
            eventCallback();
            //this.props.closeHandler();
          }

          return;
        }

        parent = parent.parentNode;

      }


    }

  }

  render() {

    this.prepareStyles();

    if (this.props.children) {
      this.prepareChildren();
    }

    return (
      <div className={styles.modal} seperator="mainModalDiv" ref={this.mainDivRef} style={this.styles}>

        <div className={styles.modalContent}
             style={{
               top: this.top,
               left: this.left,
               width: this.width
             }}
             ref={this.modalRef}>
          {this.header &&
          <div className={styles.modalHeader} ref={this.headerRef}>
            <div className={styles.headerCloseButtonContainer}>
              {this.header}
            </div>
            {this.props.hideCloseButton !== true &&
            <div className={styles.headerContent}>
              <span className={styles.close} onClick={this.props.closeHandler}>&times;</span>
            </div>}
          </div>}
          {this.body &&
          <div className={styles.modalBody} style={{height: this.height}}>
            {this.body}
          </div>}
          {this.footer &&
          <div className={styles.modalFooter}>
            {this.footer}
          </div>}
        </div>

      </div>
    );
  }


}

export default Modal;
