import React, {Component} from 'react';
import Util from "../../utils/CommonUtil";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import styles from './ElevatorCall.module.css'
import {Consts} from "../../const/Consts";
import Mesaj from "../../utils/Mesaj";
import LoginUtil from "../../utils/LoginUtil";
import MqttUtil from "../../utils/MqttUtil";
import InputForm from "../InputForm/InputForm";
import moment from "moment";

const mqtt = require('mqtt')

class ElevatorSecureCall extends Component {
  constructor(props) {
    super(props);

    this.inputParams = {
      groupId: {
        label: 'Group Id (Start from 0)', type: 'text', defaultValue: '3', placeholder: 'Id of group'
      },
      elevatorId: {
        label: 'Elevator Id (Starts from 0)',
        type: 'text',
        defaultValue: '2',
        placeholder: 'Starts from 0'
      },
      toFloor: {
        label: 'Target Floor Name', type: 'text', defaultValue: 'P4'
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      infoMessage:[]
    }

    //TODO below project Id values must be parametric
    //publish(2, "elevator/" + projectId + "/user/"+userId+"/", objectMapper.writeValueAsString("Elevator has been sent to Floor:"+ fss.flName));
    //Error user  topic             mqttService.publish(2, "elevator/" + projectId + "/"+userId+"/error", objectMapper.writeValueAsString(errMessage));
    this.userId=LoginUtil.getProfile().userId;
    //TODO projectId will be parametric
    this.projectId=1;

  }

  async componentDidMount() {
    /*

    const mqttTopicCallbacks = [
      {topic: "elevator/" + this.projectId + "/user/" + this.userId + "/#", callback: this.mqttMessageCallback},
      {topic: "test1234/deneme", callback: this.mqttMessageCallback}
    ]
     */

    const mqttSubscribeTopics=["elevator/" + this.projectId + "/user/" + this.userId + "/#"];
    this.client = await MqttUtil.createClientV2(mqttSubscribeTopics,this.mqttMessageCallback);
  }

  componentWillUnmount() {
    this.client.end();
  }

  mqttMessageCallback = (topic, message) => {
    const strMsg= message ? message.toString() : '';
    console.log('Mqtt message received. Topic', topic, 'Message:',  strMsg);
    //      {topic:"elevator/"+this.projectId+"/user/"+this.userId+"/#", callback: this.mqttMessageCallback},


    if (strMsg.length > 0) {
      this.state.infoMessage.push({type:'info',msg:strMsg,time:moment()}  );
      this.setState({infoMessage:this.state.infoMessage});
    }else {
      console.error('Empty message received please check');
    }

  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }


  sendCallMqttMessage = () => {
    const elevatorCommand = {
      groupId: parseInt(this.state.formData.groupId.value),
      elevatorId: this.state.formData.elevatorId.value,
      toFloor: this.state.formData.toFloor.value,
      userId: this.userId
    }

    /*
    	"groupId" : 1,
      "elevatorId":3,
      "toFloor":"13",
      "userId":"123"
     */

    // console.log('elevatorCommand : ', elevatorCommand);

    //"elevator/"+projectId+"/secure/from-to"
    console.log('To be sent elevatorCOmmand',elevatorCommand)
    this.client.publish("elevator/"+this.projectId+"/secure/to", JSON.stringify(elevatorCommand));
    //Mesaj.show('Your request was sent to Elevator Server over Mqtt', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  onChangeSelect = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    return <div className={styles.mainContainer} style={{overflow: 'auto', height: '89%', position: 'fixed'}}>
      <Button
        style={{marginRight: '20px'}}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {window.location.href = "/";}}>
        Anasayfa
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {LoginUtil.logout();}}>
        Çıkış
      </Button>
      <InputForm
        formData={this.state.formData}
        formDataUpdater={this.formDataUpdater}
        inputParams={this.inputParams}
        inputFormColumnWidth={'50%'}
        tabLoop={true}
        customTabKeyCode={13}
      />
      <div style={{width:'100%',textAlign:'center'}}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.sendCallMqttMessage}>
        To Floor Request
      </Button>
      </div>
      {this.renderInfoMsgs()}
    </div>
  };

  renderInfoMsgs() {
    //TODO test edilecek.
    const res=[];
    for (let i = this.state.infoMessage.length - 1; i >= 0; i--) {
      const info=this.state.infoMessage[i];
      res.push(
        <>
          <label key={i} style={{
        width:'100%',
        fontSize:'13px',
        color: info.type==='info' ? 'green' : 'red' }}>
        {info.time.format('HH:mm:ss ') + info.msg}</label><br /></>);
    }

    return res;

  }
}

export default ElevatorSecureCall;
