import PopModal from '../../components/PopModal/PopModal';
import React from 'react';
import Util from "../../utils/CommonUtil";
import 'date-fns';
import Calendar from "../../components/Calendar/Calendar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ImageCellRenderer from "../../components/GridCellRenderers/ImageCellRenderer";
import Mesaj from "../../utils/Mesaj";
import {IconButton} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Select from 'react-select';

const FormService = {

  operators: {
    'ne': function (a, b) {
      return a !== b
    },
    'eq': function (a, b) {
      return a === b
    },
    // ...
  },

  selectKeyPressEvent: function (event) {
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13) {
      event.preventDefault();
    }
  },
  getFieldsAsCommaSeperatedStr: function (columnDefs) {
    let fieldListStr = '';

    columnDefs.forEach((column, index) => {
      fieldListStr = fieldListStr + column.field + ',';
    });
    fieldListStr = fieldListStr.substring(0, fieldListStr.length - 1);
    return fieldListStr;
  },
  handleFormChange: function (event, that) {

    //event.preventDefault();  radio kontrolun ii sefer tıklanınca seçilmesine neden oluyordu
    event.persist();
    if (!(event.target.name) || event.target.value === null || event.target.value === undefined) {
      return;
    }

    let form = {...that.props.formData};
    if (event.target.getAttribute("type") === 'file') {
      const file = event.target.files[0];

      if (!file)
        return;

      const fileSize = ((file.size / 1024) / 1024).toFixed(4);  //MB

      //10 MB'dan daha büyük resimler engellendi.
      if (fileSize > 10) {
        Mesaj.show('Resim boyutu 10 MB\'dan daha büyük olamaz! Farklı bir resim yükleyiniz.', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
        event.target.value = '';
        return;
      }

      form[event.target.name].value = event.target.files[0];
    } else if (event.target.type === 'checkbox') {
      let newVal;
      if (event.target.checked)
        newVal = that.props.inputParams[event.target.name].checkedVal;
      else
        newVal = that.props.inputParams[event.target.name].unCheckedVal;

      form[event.target.name].value = newVal;
    } else
      form[event.target.name].value = event.target.value;

    //console.log('event.target.name',event.target.name,'event.target.checked',event.target.checked);
    FormService.updateForDisabled(that, form);
    that.props.formDataUpdater(form);
    if(that.props.inputParams[event.target.name].onChange)
      that.props.inputParams[event.target.name].onChange();
  },

  handleFormClick: function (event, that) {
    console.time('handleFormClick');

    //event.preventDefault();  radio kontrolun ii sefer tıklanınca seçilmesine neden oluyordu
    event.persist();
    if (!(event.target.name) || !(event.target.value)) {
      return;
    }
    console.timeEnd('handleFormClick');
  },
  calendarOnChange: function (that, name, date) {
    //console.log("calendarOnChange that : ", that, " name : ", name, " date : ", date);
    let form = {...that.props.formData};
    form[name].value = date;
    that.props.formDataUpdater(form);
  },
  prepareItem(that, name, item, index) {

    const style = that.props.inputFormColumnWidth ? {style: {width: that.props.inputFormColumnWidth}} : {style: {width: '33%'}};

    if (Object.keys(item).length === 0) {
      //Empty component dönüyor
      return <div {...style}
                  className={'input-container'}
                  key={index}
      ></div>;
    }
    const label = <label title={item.title}>
      {item.label} {
      item.title &&
      <IconButton
        size={"small"}
        style={{"padding": "0"}}
        onClick={() => Mesaj.show(item.title, "Bilgi", Mesaj.ICON_INFO, Mesaj.BUTTON_OK)}>
        <InfoIcon fontSize={"small"}/>
      </IconButton>}:
    </label>;

    const disabled = FormService.prepareDisableProperty(that, item);

    let element;
    if (item.type === 'select') {
      element = FormService.prepareSelectElement(that, name, item, disabled, index);
    }else if (item.type === 'multi-select') {
      const customStyles={
        //valueContainer:()=> ({margin:'0px',padding:'0'})
      }
      const valueList= that.props.formData[name].value ? item.options.filter(option=> that.props.formData[name].value.includes(option.value) ) : null ;
      element= <div style={{width: "54%",marginLeft: "5px"}}
      ><Select
        value={valueList}
        ref={that.props.formData[name].reactRef}
        onChange={(selectedOptions) => {
          const selectedValuesArr=selectedOptions.map(option=> option.value);
          console.log('OnChange Multiselect selectedOption',selectedOptions,'selectedValuesArr',selectedValuesArr);
          let form = {...that.props.formData};
          form[name].value = selectedValuesArr ;
          that.props.formDataUpdater(form);
        }}
        isDisabled={disabled}
        isMulti={true}
        isSearchable={true}
        name={item.name}
        options={item.options}
        noOptionsMessage={()=>item.noOptionsMessage}
        placeholder={item.placeholder}
        styles={customStyles}
      /></div>
    }else if (item.type === 'auto-complete') {

      element = <Autocomplete
        freeSolo
        autoComplete={false}
        options={item.options && Array.isArray(item.options) ? item.options.map((option) => option.brand) : []}
        getOptionSelected={(option) => option}
        inputValue={that.props.formData[name].value === null ? '' : that.props.formData[name].value}
        onChange={(event, newValue) => {
          let form = {...that.props.formData};
          form[name].value = newValue;
          that.props.formDataUpdater(form);
        }}
        onInputChange={(event, newInputValue) => {
          let form = {...that.props.formData};
          form[name].value = newInputValue;
          that.props.formDataUpdater(form);
        }}
        renderInput={(params) => {
          return <TextField
            name={name}
            {...params}
          />
        }}
      />
    } else if (item.type === 'text' || item.type === 'password') {
      element =
        <input
          type={item.type}
          ref={that.props.formData[name].reactRef}
          disabled={disabled}
          name={name}
          onChange={that.handleFormChange}
          title={that.props.formData[name].value}
          value={that.props.formData[name].value === null ? '' : that.props.formData[name].value}
          autoComplete="off"
        />;
    } else if (item.type === 'image-viewer') {
      element = that.props.formData[name].value ?
        <ImageCellRenderer height='22px' value={that.props.formData[name].value}/> : null;
    } else if (item.type === 'number') {
      element =
        <input
          type="number"
          ref={that.props.formData[name].reactRef}
          disabled={disabled}
          step={item.step !== undefined ? item.step : null}
          name={name}
          onChange={that.handleFormChange}
          title={that.props.formData[name].value}
          value={that.props.formData[name].value === null ? '' : that.props.formData[name].value}
          autoComplete="off"
        />;
    } else if (item.type === 'datetime') {
      element = <input
        ref={that.props.formData[name].reactRef}
        disabled={disabled}
        max={item.max ? item.max : ''}
        min={item.min ? item.min : ''}
        name={name}
        onChange={that.handleFormChange}
        type="datetime-local"
        value={that.props.formData[name].value === null ? '' : that.props.formData[name].value}
      />;
    } else if (item.type === 'datetime-custom') {
      const maxDate = item.max ? {maxDate: item.max} : null;
      const minDate = item.min ? {minDate: item.min} : null;
      const nullable = item.nullable;
      const dateType = item.dateType;
      element =
        <Calendar
          reactRef={that.props.formData[name].reactRef}
          name={name}
          onChange={FormService.calendarOnChange.bind(null, that, name)}
          min={minDate}
          max={maxDate}
          nullable={nullable}
          value={that.props.formData[name].value ? that.props.formData[name].value : null}
          dateType={dateType}
          disabled={disabled}
        />;
    } else if (item.type === 'button') {
      element = <button
        ref={that.props.formData[name].reactRef}
        name={name}
        onClick={item.onClick}
        type="button">{item.caption}</button>;
    } else if (item.type === 'radio') {
      const radioInputs = item.options.map((option, index) => {
        const checked = that.props.formData[name].value === option.value ? true : '';
        return (
          <label key={index}>
            <input
              type="radio"
              ref={that.props.formData[name].tabIndex['option' + option.value].reactRef}
              name={name}
              value={option.value}
              onChange={that.handleFormChange}
              disabled={disabled}
              checked={checked}/>
            {option.label}
          </label>);
      });

      element = <div className="radio-container">{radioInputs}</div>;
    } else if (item.type === 'checkbox') {
      element = <div className='checkboxContainer'>
        <input
          ref={that.props.formData[name].reactRef}
          disabled={disabled}
          name={name}
          onChange={that.handleFormChange}
          type="checkbox"
          value={that.props.formData[name].value === null ? '' : that.props.formData[name].value}
          checked={Boolean(that.props.formData[name].value === that.props.inputParams[name].checkedVal)}
        /></div>;
    } else if (item.type === 'html') {
      element = that.props.formData[name].value;
    } else if (item.type === 'image') {
      element =
        <input
          style={{marginTop: '3px', marginLeft: '2px'}}
          type="file"
          ref={that.props.formData[name].reactRef}
          disabled={disabled}
          name={name}
          onChange={that.handleFormChange}
          title={'Dosya yüklemek için seçiniz'}
          autoComplete="off"
        />;
    } else {
      console.error('Bilinmeyen type:', item);
    }

    return (<div {...style}
                 className={'input-container'}
                 key={index}
                 onBlur={item.onBlur ? item.onBlur : null}
    >{label}{element}</div>);
  },
  prepareDisableProperty: function (that, item) {

    if (!item || !item.disabled) {
      //disabled field yoksa null döner
      return null;
    }

    if (typeof item.disabled === 'boolean') {
      return item.disabled;
    }

    if (typeof item.disabled === 'function') {
      return item.disabled();
    }

    if (typeof item.disabled !== 'object') {
      console.error('Bilinmeyen disable object tipi gelmiştir', item)
      return null;
    }

    const disableCondition = item.disabled;

    const toBeCheckedFieldName = disableCondition.field;
    const itemDisabled = FormService.operators[disableCondition.operation](disableCondition.value, that.props.formData[toBeCheckedFieldName].value);

    return itemDisabled;
  },

  prepareSelectElement: function (that, name, item, disabled, index) {


    if (Array.isArray(item.options)) {
      const options = item.options.map((option, index) => {
        return (<option
          key={index}
          value={option.value}
        >{option.label}</option>);
      });
      return (
        <div className="select-container">
          <select
            onKeyPress={FormService.selectKeyPressEvent}
            ref={that.props.formData[name].reactRef}
            disabled={disabled}
            name={name}
            onChange={that.handleFormChange}
            value={that.props.formData[name].value === null ? '' : that.props.formData[name].value}
          >{options}</select>
        </div>);
    } else {
      //options array değilse, popModal parametrelerini içeren bir obje demektir.
      const stateForm = that.props.formData;
      let showPopModal;
      if (!stateForm[name].showPopModal || stateForm[name].showPopModal === false) {
        showPopModal = false;
      } else {
        showPopModal = true;
      }

      //Eğer form state içinde bir seçim varsa bu seçime karşılık gelen option gösterilmelidir.
      let selectedOption = null;
      if (that.props.formData[name].value) {
        selectedOption = <option label={that.props.formData[name].label}>{that.props.formData[name].value}</option>
      }

      return (
        <div className="select-container">
          {showPopModal &&
          <PopModal
            {...item.options}
            closeHandler={() => FormService.setPopModalState(that, name, false)}
            parentItemName={name}
          />}

          <select
            onKeyPress={FormService.selectKeyPressEvent}
            ref={that.props.formData[name].reactRef}
            disabled={disabled}
            name={name}
            onChange={that.handleFormChange}
            onClick={(e) => FormService.setPopModalState(that, name, true, e)}
            value={item.value}
          >{selectedOption}</select>
        </div>);
    }

  },

  createInitialFormState: function (inputParams) {
    let formState = {};
    let tabIndexCounter = 1000;
    //parametrelerden value ve PopModal statelerini tutan listenin ilk hali oluşturuluyor.
    const toBeIteratedKeys = Object.keys(inputParams)
    toBeIteratedKeys && toBeIteratedKeys.forEach((key, index) => {

      if (inputParams[key].type === 'radio') {
        //Her bir radio option için ayrı ayrı tabIndex değerleri oluşturuluyor
        const tabIndexes = {};

        for (let i = 0; i < inputParams[key].options.length; i++) {
          //Object.keys(inputParams[key].options).forEach( (option, index) => {
          const option = inputParams[key].options[i];
          tabIndexes['option' + option.value] = {
            optionTabIndex: tabIndexCounter++,
            optionName: key + option.value,
            reactRef: React.createRef()
          };
        }
        formState[key] = {
          value: inputParams[key].defaultValue,
          tabIndex: tabIndexes
        };
      } else if (inputParams[key].type === 'html') {
        formState[key] = {
          value: inputParams[key].defaultValue,
          reactRef: React.createRef()
        };
      } else {
        formState[key] = {
          value: inputParams[key].defaultValue,
          reactRef: React.createRef(),
          tabIndex: tabIndexCounter++
        };
      }


    });

    return formState;
  },
  updateFormStateData: function (inputParams, formState, baslikDb) {

    Object.keys(inputParams).forEach((key, index) => {
      if (inputParams[key].dbField === null || inputParams[key].type === 'html') {
        return;
      }
      if (!inputParams[key].dbField) {
        throw new Error(key + " değeri için dbField değeri tanımlanmamıştır.");
      }

      formState[key].value = baslikDb[inputParams[key].dbField];
      if (baslikDb[inputParams[key].dbField + '_label']) {
        formState[key].label = baslikDb[inputParams[key].dbField + '_label'];
      }
    });
    return formState;

  },

  setPopModalState: function (that, itemName, state, event) {
    if (event && event.target) {
      event.target.blur();
    }

    //event.preventDefault();
    //state true, false değerlerini alır
    let form = {...that.props.formData};
    form[itemName].showPopModal = state;
    that.props.formDataUpdater(form);
  },

  updateForDisabled(that, form) {
    //parametrelerden value ve PopModal statelerini tutan listenin ilk hali oluşturuluyor.
    Object.keys(form).forEach((itemName, index) => {
      const itemDisabled = FormService.prepareDisableProperty(that, that.props.inputParams[itemName]);
      if (itemDisabled && Util.isTrue(that.props.inputParams[itemName].disabled.clear)) {
        form[itemName].value = '';
      }
    });
  },


  prepareStateFormToPost(inputParams, formData, exceptionList) {
    let toBePostedForm = {};
    Object.keys(formData).forEach((itemName, index) => {
      if (exceptionList.includes(itemName))
        return;

      if (inputParams[itemName].type === 'datetime') {
        const dateVal = formData[itemName].value;
        if (dateVal) {
          toBePostedForm[itemName] = dateVal.replace('T', ' ');
        } else {
          toBePostedForm[itemName] = '';
        }
      } else {
        toBePostedForm[itemName] = formData[itemName].value;
      }

    });

    return toBePostedForm;
  }
}

export default FormService;
