import React, {Component} from 'react';
import styles from './Kategori.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import LoginUtil from "../../utils/LoginUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import ImageCellRenderer from "../GridCellRenderers/ImageCellRenderer";

class Kategori extends Component {
  constructor(props) {
    super(props);

    const categoryColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.categoryId',
        hide: true
      },
      {
        headerName: 'Kategori',
        field: 'name',
        cellRenderer: "loadingCellRenderer",
        resizable: true,
        flex: 1
      }
    ];

    this.inputParams = {
      name: {
        label: 'Kategori Adı', type: 'text', defaultValue: ''
      },
      parentCategory: {
        label: 'Üst Kategori', type: 'select', defaultValue: ''
        , options: {
          lazy: false,
          columnDefs: categoryColumnDefs,
          urlEndPoint: "/category/get-categories-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Kategori Seçiniz',
          onSelection: this.onCategorySelection.bind(this)
        },
      },
      image: {
        label: 'Resim Yükleyin', type: 'image'
      },
      priority: {
        label: 'Öncelik Değeri', type: 'number', defaultValue: 0
      },
      orderNotificationPhoneCsv: {
        label: 'Kategori bildirim telefon listesi', type: 'text', title:'Kategoriye bağlı bir ürün sipariş edildiğinde bu listedeki telefonlara bildirim gönderilecektir. Restoran gibi kategoriler için virgül ile ayrılmış numaraları buraya giriniz. Örn: 5301234567,555234780'
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    }

    this.colDefs = [
      {
        headerName: 'Kategori Id',
        field: 'id'
      },
      {
        headerName: 'Pasif',
        field: 'deleted',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Kategori Adı',
        field: 'name',
        floatingFilter: true
      },
      {
        headerName: 'Ürün Resmi',
        field: 'imageUrl',
        cellRenderer: 'ImageCellRenderer'
      },
      {
        headerName: 'TreePath',
        floatingFilter: true,
        field: 'treePath'
      },
      {
        headerName: 'Kategori Hiyerarşisi',
        field: 'hierarchy'
      },
      {
        headerName: 'Öncelik Değeri',
        filter: 'agNumberColumnFilter',
        field: 'priority'
      },
      {
        headerName: 'Bildirim Telefonları',
        field: 'orderNotificationPhoneCsv'
      },
    ];

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      editData: null
    }

    this.gridSettingJson = localStorage.getItem('categoryGridSetting');
  }

  componentDidMount() {
    this.loadData().then();
  }

  onCategorySelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].categoryId;
    formData[parentItemName].label = selectedRow[0].name;
    this.setState({formData: formData});
  }

  loadData = async () => {
    this.setState({loading: true});
    let apiRes;

    const projectId = Util.isAdminUser() ? this.state.formData.projectId.value : Util.getProjectId();

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/category/list-for-panel-with-project-id',
      'POST', Util.HEADERS_FOR_GET, projectId);

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    //gelen kategoriler map'e konuluyor, treePath'den üst kategorilerin isimlerini getirmede kullanılacak.
    let categoryMap = new Map();
    apiRes.forEach(function (item, index) {
      categoryMap.set(item.id + '', item.name);
    });

    //treePath, üst kategori isimleri haline getiriliyor.
    apiRes.forEach(function (item) {
      if (item.treePath) {
        const parentCategoryNames = item.treePath.split("/").filter(item => item)
          .map(item => categoryMap.get(item + '')).join(' > ');
        item.hierarchy = parentCategoryNames;
      }
    });

    this.setState({
      loading: false,
      gridRowData: this.prepareRowData(apiRes)
    })

    if (!this.gridSettingJson)
      this.gridApi.sizeColumnsToFit();
  }

  close = (event) => {
    this.props.closeTab(event, this.props.tabId);
  }

  askForDeleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();

    //console.log('selectdata',selectdata);
    if (selectedData.length === 0)
      Mesaj.show('Silinecek kayıt seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else
      Mesaj.show(selectedData[0].data.name + ' kategorisini ' + (makePassive ? 'pasifleştirmek' : 'silmek') +
        ' istiyor musunuz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.deleteRow.bind(this, makePassive));
  }

  deleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();
    let currentRowIndex = 0;
    selectedData.forEach((node) => {
      currentRowIndex = node.rowIndex - 1;
      this.delData(node.data.id, makePassive, currentRowIndex).then();
    });
  }

  async delData(id, makePassive, rowIndex) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("makePassive", makePassive ? 'true' : 'false');

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/category/del-category',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  refreshList = () => {
    this.loadData().then();
  }

  excelExport = () => {
    const params = {
      fileName: "Kategori-Listesi.xlsx",
      sheetName: 'Kategori-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.gridSettingJson)
      this.gridColumnApi.setColumnState(JSON.parse(this.gridSettingJson));
  };

  saveGridSetting = () => {
    localStorage.removeItem('categoryGridSetting');
    let savedState = this.gridColumnApi.getColumnState();
    localStorage.setItem('categoryGridSetting', JSON.stringify(savedState));
    Mesaj.show('Tablo ayarları kayıt edildi!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  onFirstDataRendered = params => {
    if (!this.gridSettingJson)
      params.api.sizeColumnsToFit();
  };

  prepareRowData(list) {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  addEditCategory = async () => {
    if (!this.state.formData.name.value) {
      Mesaj.show('Kategory Adı Boş Olamaz', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (this.state.formData.name.value.length > 50) {
      Mesaj.show('Kategori adı 50 karakterden fazla olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.editData && !this.state.formData.image.value) {
      Mesaj.show('Lütfen bir dosya yükleyiniz', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (this.state.formData.name.value === this.state.formData.parentCategory.label) {
      Mesaj.show('Üst kategori olarak kendisini seçemezsiniz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    let nameExist = false;
    const that = this;

    this.gridApi.forEachNode(function (rowNode, index) {
      if (!that.state.editData &&
        rowNode.data.name.toString().toLocaleLowerCase('tr') ===
        that.state.formData.name.value.toLocaleLowerCase('tr')) {
        nameExist = true;
        return;
      }
    }, this);

    if (nameExist) {
      Mesaj.show('Bu isimde bir kategori daha önceden oluşturulmuş', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({loading: true});

    const data = new FormData();
    if (this.state.editData)
      data.append('categoryId', this.state.editData.id)

    if (this.state.formData.image.value)
      data.append('image', this.state.formData.image.value)

    data.append('name', this.state.formData.name.value);
    data.append('parentCategoryId', this.state.formData.parentCategory.value);
    data.append('priority', this.state.formData.priority.value);
    data.append('categoryProjectId', this.state.formData.projectId.value || 1);
    data.append('orderNotificationPhoneCsv', this.state.formData.orderNotificationPhoneCsv.value)

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken()
    }

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/category/add-edit-category',
      'POST', headers, data);
    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.state.formData.name.value = '';
    this.state.formData.image.value = null;
    this.state.formData.parentCategory.value = 0;
    this.state.formData.parentCategory.label = '';
    this.state.formData.priority.value = 0;
    this.state.formData.orderNotificationPhoneCsv.value='';
    this.setState({editData: null});
    Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Kategori düzenleme başarılı', 'Bilgi',
      Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then(() => {
      this.gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.id === apiRes.categoryId) {
          rowNode.setSelected(true);
          that.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    });
  }

  editCategory = async editData => {
    // this.inputParams.projectId.disabled = true;
    this.state.formData.parentCategory.value = 0;
    this.state.formData.parentCategory.label = '';

    if (editData.treePath) {
      const treePaths = editData.treePath.split("/");
      const urlencoded = new URLSearchParams();
      urlencoded.append("categoryId", treePaths[treePaths.length - 2]);

      const headers = {
        'Authorization': 'Bearer ' + LoginUtil.getToken(),
        'Content-Type': 'application/x-www-form-urlencoded'
      }

      this.setState({loading: true});
      let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/category/get-category-by-id',
        'POST', headers, urlencoded);

      if (!apiRes) {
        this.setState({loading: false});
        Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : ', Sunucuda Hata Oluştu!', 'Hata',
          Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
        return;
      }

      this.state.formData.parentCategory.value = apiRes[0].id;
      this.state.formData.parentCategory.label = apiRes[0].name;
    }

    this.state.formData.name.value = editData.name;
    this.state.formData.priority.value = editData.priority;
    this.state.formData.image.value = editData.imageUrl;
    this.state.formData.orderNotificationPhoneCsv.value=editData.orderNotificationPhoneCsv;
    this.setState({editData: editData, loading: false});
  }

  getContextMenuItems = params => {
    if (params.node.group === true)
      return null;

    const menu = [
      {
        name: 'Kategoriyi Sil',
        action: this.askForDeleteRow.bind(this, false),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Kategoriyi Pasif Yap',
        disabled: params.node.data.deleted,
        action: this.askForDeleteRow.bind(this, true),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Kategoriyi Aktifleştir',
        disabled: !params.node.data.deleted,
        action: this.makeActive.bind(this, params.node.data.id)
      },
      {
        name: 'Kategoriyi Düzenle',
        action: this.editCategory.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      }
    ];

    return menu;
  };

  async makeActive(categoryId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("categoryId", categoryId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/category/activate-category',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu. Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';

    return null;
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer}>
        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

        {this.state.editData &&
        <div style={{display: 'flex'}}>
          <div style={{width: '40.6%', textAlign: 'right'}}>
            <label className={styles.imageLabel} title={'Değiştirmek için yeni resim yükleyiniz.'}>Mevcut Resim:</label>
          </div>
          <div className={styles.currentImageContainer}>
            <ImageCellRenderer height='22px' value={this.state.formData.image.value}/>
          </div>
        </div>
        }
        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />
        <div>
          <Button
            disabled={this.state.loading}
            style={{position: 'absolute', left: '40.6%', marginTop: '6px'}}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.addEditCategory.bind(this)}
          >
            {this.state.editData ? 'Kategori Güncelle' : 'Kategori Ekle'}
          </Button>
          {this.state.editData &&
          <Button
            disabled={this.state.loading}
            style={{marginTop: '6px'}}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={() => {
              this.state.formData.parentCategory.value = 0;
              this.state.formData.parentCategory.label = '';
              this.formDataUpdater(this.state.formData);
            }}
          >
            Üst Kategoriyi Kaldır
          </Button>}
        </div>
      </div>

      <div>
        <Toolbar handlers={
          [
            {callBack: this.refreshList, title: 'Yenile', icon: '/images/menu/mnuAutorenew_24px.svg'},
            {callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'},
            {
              callBack: this.saveGridSetting.bind(this),
              title: 'Tablo Ayarlarını Kaydet',
              icon: '/images/menu/24px/mnuSaveSettings.png'
            }
          ]
        }
        />
      </div>

      <div
        style={{
          height: 'calc(100% - 250px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          getContextMenuItems={this.getContextMenuItems}
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          getRowClass={this.getRowClass}
        >
        </Grid>
      </div>
    </div>
  };
}

export default Kategori;
