import React, { Component, useRef, useState, useEffect, useContext } from 'react';
import styles from './Styles/RobotRemoteproject.module.css';
import StateContainer from './Components/StateContainer';
import StateWithIcon from './Components/StateWithIcon';
import RobotCamera from './Components/RobotCamera';
import RobotPayload from './Components/RobotPayload';
import RobotControl from './Components/RobotControl';
import RobotLedModal from './Components/RobotLedModal';
import RobotLiveMap from './Components/RobotLiveMap';
import Grid from '@material-ui/core/Grid';
import { getTargets } from './Utils/configUtil';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RobotHeader from './Components/RobotHeader';
import { topicInfo, modals, wsConnectionStatus } from './RobotEnums';
import {
  getBatteryImage,
  getElevatorImage,
  hasCameraPermission,
  hasRobotControlPermission,
  hasSystemAdminPermission,
  hasLivePosePermission
} from './Utils/util';
import Util from '../../utils/CommonUtil';
import { Consts } from '../../const/Consts';
import config from './config';
import LoginUtil from '../../utils/LoginUtil';
import ChooseProject from './Components/ChooseProject';
import { rrLanguage } from './Utils/rrLanguage';
import { RobotRemoteContext } from './RobotRemoteContext/RobotRemoteContext';
import RobotCallModal from './Components/RobotCallModal';
import LiveSwitch from './Components/InputElements/LiveSwitch';
import DarkModeSwitch from './Components/InputElements/DarkModeSwitch';

const RobotRemoteProject = () => {
  var doorImg = require('../../assets/RobotRemoteImages/door/disabled.png');
  const { lang } = useContext(RobotRemoteContext);
  rrLanguage.setLanguage(lang);
  const ws = useRef(null);
  const [projectId, setProjectId] = useState(null);
  const [robotId, setRobotId] = useState();
  const [robots, setRobots] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [navHistory, setNavHistory] = useState({});
  const [elevInfo, setElevInfo] = useState({});
  const [robotState, setRobotState] = useState({});
  const [livePose, setLivePose] = useState({});
  const [erStop, setErstop] = useState({});
  const [commStatus, setCommStatus] = useState(null);
  const [enableCmdxz, setEnableCmdxz] = useState(null);
  const [liveStream, setLiveStream] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [activeModalId, setActiveModalId] = useState(null);
  const [activeModalData, setActiveModalData] = useState(null);
  const [robotCallModal, setRobotCallModal] = useState(null);
  const [activeMap2, setActiveMap2] = useState({
    map: null,
    compressedMap: null,
    locations: null,
    robotLocation: { x: null, y: null, z: null, lastUpdateDate: new Date() },
    isEnableLivePose: false
  });
  const [enableCmdxzAssignedUserId, setEnableCmdxzAssignedUserId] = useState(null);
  const [cameraSettings, setCameraSettings] = useState({
    isEnable: false,
    isOpenFront: false
  });
  const [lidState, setLidState] = useState({
    lt: false, //left top lid
    rt: false, //right top lid
    lb: false, //left bottom lid
    rb: false //right bottom lid
  });
  const [projectConfig, setProjectConfig] = useState(null);
  const [projectNames, setProjectNames] = useState([]);
  const [activeProjectName, setActiveProjectName] = useState(null);
  const [modalRefresh, setModalRefresh] = useState(false);
  const [enableCmdxzAssignedUsername, setEnableCmdxzAssignedUsername] = useState(null);
  const [disabledManualControl, setDisabledManualControl] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const userProfile = LoginUtil.getProfile();

  const payloadWsGeneric = (topicName, robot, type, message) => {
    const payload = payloadGeneric(topicName, robot, type, message);

    if (payload != undefined) {
      ws.current.send(JSON.stringify(payload));
    }
  };

  const payloadGeneric = (topicName, robot, type, message) => {
    if (wsConnectionStatus.OPEN == ws.current.readyState) {
      return {
        request: type,
        project_id: projectId,
        robot_id: robot || robotId,
        topic: topicName,
        message: message || ''
      };
    }
  };

  async function getProjectConfig() {
    let apiRes = await Util.apiRequest(
      process.env.REACT_APP_HTTP_URL + config.GET_PROJECT_CONFIG_API,
      'POST',
      Util.HEADERS_FOR_GET,
      JSON.stringify({ project_id: projectId })
    );

    if (apiRes.result == Consts.RESULT_SUCCESS && apiRes.resultData[0]) {
      setProjectConfig(JSON.parse(apiRes.resultData[0].json_config));
      return;
    }
  }

  async function getUsername() {
    let apiRes = await Util.apiRequest(
      process.env.REACT_APP_HTTP_URL + config.GET_USERNAME_API,
      'POST',
      Util.HEADERS_FOR_GET,
      JSON.stringify({ project_id: projectId, user_id: enableCmdxzAssignedUserId })
    );

    if (apiRes.result == Consts.RESULT_SUCCESS && apiRes.resultData[0]) {
      setEnableCmdxzAssignedUsername(apiRes.resultData[0].name);
      return;
    }
  }
  async function getRobots() {
    let apiRes = await Util.apiRequest(
      process.env.REACT_APP_HTTP_URL + config.GET_ROBOT_API,
      'POST',
      Util.HEADERS_FOR_GET,
      JSON.stringify({ project_id: projectId })
    );

    if (apiRes.result == Consts.RESULT_SUCCESS && apiRes.resultData.length > 0) {
      setRobots(
        apiRes.resultData.map(robot => {
          return robot.robot_id;
        })
      );
      setRobotId(apiRes.resultData[0].robot_id);
      return;
    }
  }

  async function getProjectNames() {
    let apiRes = await Util.apiRequest(
      process.env.REACT_APP_HTTP_URL + config.GET_PROJECT_API,
      'GET',
      Util.HEADERS_FOR_GET,
      null
    );

    if (apiRes.result == Consts.RESULT_SUCCESS) {
      setProjectNames(
        apiRes.resultData.map(robot => {
          return robot.project_name;
        })
      );
      return;
    }
  }

  useEffect(() => {
    const getUniqueProjectIdList = userProfile => {
      return userProfile.projectRoles
        .map(item => item.projectId)
        .filter((value, index, self) => self.indexOf(value) === index);
    };
    const uniqueProjectIdList = getUniqueProjectIdList(userProfile);

    if (uniqueProjectIdList.length === 1) {
      setActiveProjectName(projectNames[uniqueProjectIdList[0].toString()]);
    }
  }, [projectNames]);

  useEffect(() => {
    async function getProjectsId() {
      let apiRes = await Util.apiRequest(
        process.env.REACT_APP_HTTP_URL + config.GET_PROJECT_API,
        'GET',
        Util.HEADERS_FOR_GET,
        null
      );

      if (apiRes.result == Consts.RESULT_SUCCESS) {
        setUserProjects(
          apiRes.resultData.map(item => {
            return item.project_id;
          })
        );
        return;
      }
    }

    const getUniqueProjectIdList = userProfile => {
      return userProfile.projectRoles
        .map(item => item.projectId)
        .filter((value, index, self) => self.indexOf(value) === index);
    };
    const uniqueProjectIdList = getUniqueProjectIdList(userProfile);

    if (uniqueProjectIdList.length === 1) {
      if (userProfile.projectRoles[0].role === Consts.ROLE_RR_SYSTEM_ADMIN) {
        getProjectsId();
      } else {
        setProjectId(uniqueProjectIdList[0].toString());
      }
    } else {
      setUserProjects(uniqueProjectIdList);
    }
    getProjectNames();
  }, []);

  useEffect(() => {
    if (projectId) {
      getProjectConfig();
      getRobots();
    }
  }, [projectId]);

  useEffect(() => {
    if (ws.current) {
      ws.current.close();
    }
    resetData();
    runWebSocket();
  }, [robotId]);

  useEffect(() => {
    if (hasCameraPermission(userProfile.projectRoles, projectId)) {
      if (cameraSettings.isEnable) {
        payloadWsGeneric(
          config.pubsTopicNames.enable_live_stream,
          robotId,
          'PUBLISH',
          '1'
        );
        payloadWsGeneric(config.subsTopicNames.live_stream, robotId, 'SUBSCRIBE');
      } else {
        payloadWsGeneric(config.subsTopicNames.live_stream, robotId, 'UNSUBSCRIBE');
        if (hasSystemAdminPermission(userProfile.projectRoles, projectId)) {
          payloadWsGeneric(
            config.pubsTopicNames.enable_live_stream,
            robotId,
            'PUBLISH',
            '0'
          );
        }
      }
    }
  }, [cameraSettings.isEnable]);

  useEffect(() => {
    if (enableCmdxzAssignedUserId != null) {
      getUsername();
    }
  }, [enableCmdxzAssignedUserId]);

  useEffect(() => {
    async function getMap() {
      let res = await Util.apiRequest(
        process.env.REACT_APP_HTTP_URL + config.GET_MAP_API,
        'POST',
        Util.HEADERS_FOR_GET,
        JSON.stringify({
          project_id: projectId,
          site: navHistory.siteName,
          floor: navHistory.floorName
        })
      );
      if (res.result == Consts.RESULT_SUCCESS && res.resultData.length > 0) {
        Util.toDataURL(`${config.imageUrl}/${res.resultData[0].image_file_name}`).then(
          response => {
            setActiveMap2(prev => ({
              ...prev,
              map: res.resultData[0]
            }));
          }
        );
        return;
      }
    }
    if (projectId && navHistory.siteName && navHistory.floorName) {
      getMap();
      setActiveMap2(prev => ({
        ...prev,
        locations: getTargets(
          navHistory.siteName,
          navHistory.floorName,
          projectConfig.targets.list
        )
      }));
    }
  }, [projectId, navHistory.siteName, navHistory.floorName]);
  useEffect(() => {
    if (!activeMap2.isEnableLivePose) {
      setActiveMap2(prev => ({
        ...prev,
        robotLocation: {
          ...prev.robotLocation,
          x: navHistory.positionX,
          y: navHistory.positionY,
          // TODO z: navHistory.orientationYaw
          z: Math.atan2(
            0,
            -2.0 *
              (navHistory.orientationZ * navHistory.orientationZ +
                navHistory.orientationW * navHistory.orientationW) +
              1.0
          ),

          lastUpdateDate: new Date()
        }
      }));
    }
  }, [navHistory.positionX, navHistory.positionY]);
  useEffect(() => {
    if (activeMap2.isEnableLivePose && Object.keys(livePose).length !== 0) {
      var arr = livePose.split(',');
      setActiveMap2(prev => ({
        ...prev,
        robotLocation: { x: arr[0], y: arr[1], z: arr[2], lastUpdateDate: new Date() }
      }));
    }
  }, [livePose]);
  const runWebSocket = () => {
    if (robotId !== undefined) {
      ws.current = new WebSocket(
        process.env.REACT_APP_WEBSOCKET_URL + '/' + LoginUtil.getToken()
      );
      ws.current.onopen = () => {};
      ws.current.onclose = () => console.log('ws closed');
      const wsCurrent = ws.current;
      ws.current.onmessage = event => {
        const data = JSON.parse(event.data);
        switch (data.topic) {
          case config.ClientConnected:
            config.subsTopicNames.rr_view_only.forEach(topicName => {
              payloadWsGeneric(topicName, robotId, 'SUBSCRIBE');
            });
            break;
          case topicInfo.NavHistory.apiName:
            setNavHistory(data.message);
            break;
          case topicInfo.LivePose.apiName:
            setLivePose(data.message);
            break;
          case topicInfo.State.apiName:
            setRobotState(data.message);
            break;
          case topicInfo.ElevInfo.apiName:
            setElevInfo(data.message);
            break;
          case topicInfo.CommStatus.apiName:
            setCommStatus(data.message);
            break;
          case topicInfo.ErStop.apiName:
            setErstop(data.message);
            break;
          case topicInfo.LiveStream.apiName:
            setLiveStream(data.message);
            break;
          case topicInfo.EnableCmdxz.apiName:
            setEnableCmdxz(data.message);
            break;
          case topicInfo.EnableCmdxzAssignedUserId.apiName:
            setEnableCmdxzAssignedUserId(data.message);
            break;
          default:
        }
      };

      return () => {
        wsCurrent.close();
      };
    }
  };
  const resetData = () => {
    setNavHistory({});
    setLivePose({});
    setRobotState({});
    setElevInfo({});
    setCommStatus(null);
    // TODO setErstop
    setEnableCmdxz(null);
    setActiveMap2({
      map: null,
      locations: null,
      robotLocation: { x: 0, y: 0, z: 0 },
      isEnableLivePose: false
    });
    setCameraSettings({
      isEnable: false,
      isOpenFront: false
    });
    setLiveStream(null);
    setEnableCmdxzAssignedUserId(null);
    setDisabledManualControl(false);
  };

  useEffect(() => {
    async function getStatusLedModalData() {
      if (activeModal !== null) {
        let apiRes = await Util.apiRequest(
          process.env.REACT_APP_HTTP_URL + config.GET_ALARM_API,
          'POST',
          Util.HEADERS_FOR_GET,
          JSON.stringify({
            project_id: projectId,
            robot_id: robotId,
            alarm_id: activeModalId
          })
        );

        if (apiRes.result == Consts.RESULT_SUCCESS && apiRes.resultData.length > 0) {
          setActiveModalData(apiRes.resultData);
          return;
        }
      }
    }

    async function getNavHistoryModalData() {
      if (activeModal !== null) {
        let apiRes = await Util.apiRequest(
          process.env.REACT_APP_HTTP_URL + config.GET_NAV_HISTORY_API,
          'POST',
          Util.HEADERS_FOR_GET,
          JSON.stringify({
            project_id: projectId,
            robot_id: robotId
          })
        );

        if (apiRes.result == Consts.RESULT_SUCCESS && apiRes.resultData.length > 0) {
          setActiveModalData(apiRes.resultData);
          return;
        }
      }
    }

    if (activeModal != null && activeModal != 'NavHistory') {
      getStatusLedModalData();
    } else if (activeModal != null && activeModal == 'NavHistory') {
      getNavHistoryModalData();
    }

    if (config.isShowConsoleDebug) {
      console.log('activeModal', activeModal);
    }
  }, [activeModal, modalRefresh]);
  useEffect(() => {
    if (robotId) {
      if (activeMap2.isEnableLivePose) {
        payloadWsGeneric(config.pubsTopicNames.enable_live_nav, robotId, 'PUBLISH', '1');
        payloadWsGeneric(config.subsTopicNames.live_pose, robotId, 'SUBSCRIBE');
      } else {
        if (hasSystemAdminPermission(userProfile.projectRoles, projectId)) {
          payloadWsGeneric(
            config.pubsTopicNames.enable_live_nav,
            robotId,
            'PUBLISH',
            '0'
          );
        }
        payloadWsGeneric(config.subsTopicNames.live_pose, robotId, 'UNSUBSCRIBE');
      }
    }
  }, [activeMap2.isEnableLivePose]);
  return (
    <div className={styles.robot2Container}>
      <RobotHeader
        robots={robots}
        setRobotId={setRobotId}
        robotId={robotId}
        setLidState={setLidState}
        activeProjectName={activeProjectName}
      />

      <Grid container spacing={3}>
        <Grid container spacing={2} xs={12} md={6} margin={'1px'}>
          <Grid container spacing={1} xs={8} sm={8} md={9}>
            <Grid item xs={6} sm={6} md={4}>
              <StateContainer
                title={rrLanguage.robotSiteFloor}
                value={navHistory.siteName?.concat(' / ' + navHistory.floorName)}
                color="white"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <StateContainer
                title={rrLanguage.state}
                value={
                  rrLanguage[navHistory.state]
                    ? rrLanguage[navHistory.state]
                    : navHistory.state
                }
                color="white"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <StateContainer
                title={rrLanguage.targetSiteFloor}
                value="n/a"
                color="white"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <StateWithIcon
                icon={getBatteryImage(navHistory.batteryPercentage, navHistory.charging)}
                value={navHistory.batteryPercentage?.toFixed(2).concat('%')}
                marginTop={'25px'}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <StateWithIcon
                icon={getElevatorImage(
                  elevInfo.elev_assigned,
                  elevInfo.assigned_elev_independent
                )}
                value={elevInfo?.assigned_elev_group_id
                  ?.toString()
                  .concat(
                    ' / ' + elevInfo.assigned_elev_name,
                    ' / ' + elevInfo.assigned_elev_floor
                  )}
                marginTop={'10px'}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <StateWithIcon icon={doorImg} value="n/a" marginTop={'25px'} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <RobotCamera
                src={`data:image/jpg;base64,${liveStream}`}
                cameraSettings={cameraSettings}
                setCameraSettings={setCameraSettings}
                hasPermission={hasCameraPermission(userProfile.projectRoles, projectId)}
                projectId={projectId}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                md={6}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setActiveModal(modals.RobotState);
                    setActiveModalId(100);
                  }}>
                  <FiberManualRecordIcon
                    style={{
                      color: commStatus == 1 ? 'green' : '#e53935'
                    }}
                  />
                  <span>{robotId}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setActiveModal(modals.Estop);
                    setActiveModalId(101);
                  }}>
                  <FiberManualRecordIcon style={{ color: '#6c6c6c' }} />
                  <span>{rrLanguage.eStop}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setActiveModal(modals.ManualControl);
                    setActiveModalId(102);
                  }}>
                  <FiberManualRecordIcon
                    style={{
                      color: enableCmdxz == 1 ? 'green' : '#6c6c6c'
                    }}
                  />
                  <span>
                    {rrLanguage.manualController.concat(
                      enableCmdxz == 1 ? ' - ' + enableCmdxzAssignedUsername : ''
                    )}
                  </span>
                </div>
                <div>
                  <button
                    style={{
                      margin: '2px',
                      padding: '10px',
                      fontSize: '1rem',
                      width: '80px',
                      height: '60px',
                      borderRadius: '.4rem',
                      backgroundColor: 'var(--firstColor)',
                      border: 'none',
                      color: 'var(--textColor)',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setActiveModal(modals.NavHistory);
                    }}>
                    {rrLanguage.robotHistory}
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} xs={4} sm={4} md={3}>
            <Grid item xs={12} sm={6} md={12}>
              <RobotPayload robotState={robotState} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <RobotControl
              payloadWsGeneric={payloadWsGeneric}
              ws={ws}
              robotId={robotId}
              navHistory={navHistory}
              lidState={lidState}
              setLidState={setLidState}
              setRobotCallModal={setRobotCallModal}
              hasPermission={hasRobotControlPermission(
                userProfile.projectRoles,
                projectId,
                enableCmdxzAssignedUserId
              )}
              enableCmdxz={enableCmdxz}
              enableCmdxzAssignedUserId={enableCmdxzAssignedUserId}
              userId={userProfile.userId}
              disabledControl={disabledManualControl}
              setDisabledControl={setDisabledManualControl}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>
          <Grid
            justifyContent="space-between"
            alignItems="center"
            style={{ display: 'flex' }}>
            <span>
              <LiveSwitch
                isEnable={activeMap2.isEnableLivePose}
                isDisabled={!hasLivePosePermission(userProfile.projectRoles, projectId)}
                handleChange={() => {
                  setActiveMap2(prev => ({
                    ...prev,
                    isEnableLivePose: !prev.isEnableLivePose
                  }));
                }}
              />
              <DarkModeSwitch
                isEnable={isDarkMode}
                handleChange={() => setIsDarkMode(!isDarkMode)}
              />
            </span>

            <span>{activeMap2.robotLocation.lastUpdateDate?.toLocaleString()}</span>
          </Grid>
          <RobotLiveMap
            livePose={livePose}
            activeMap={activeMap2}
            isDarkMode={isDarkMode}
          />
        </Grid>
      </Grid>
      <RobotLedModal
        handleClose={() => setActiveModal(null)}
        isOpen={activeModal != null}
        title={activeModal}
        data={activeModalData}
        setModalRefresh={setModalRefresh}
        modalRefresh={modalRefresh}
      />
      <RobotCallModal
        handleClose={() => setRobotCallModal(null)}
        isOpen={robotCallModal != null}
        title={rrLanguage.robotCall}
        payloadWsGeneric={payloadWsGeneric}
        ws={ws}
        robotId={robotId}
        projectConfig={projectConfig}
      />
      {userProjects.length > 1 ? (
        <ChooseProject
          uniqueProjectIdList={userProjects}
          setProjectId={setProjectId}
          getRobots={getRobots}
          projectNames={projectNames}
          getProjectNames={getProjectNames}
          setActiveProjectName={setActiveProjectName}
        />
      ) : null}
    </div>
  );
};
export default RobotRemoteProject;
