import {createStore} from 'redux'
import rootReducer from '../reducers/rootReducer';
import storageSession from 'redux-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {persistReducer, persistStore} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
  blacklist: ['alert']
};

const rootPersistReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(rootPersistReducer);
const persistor = persistStore(store);

export default store;
