import React, {Component} from 'react';
import styles from './Order.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import Dialog from "@material-ui/core/Dialog";
import OrderDetail from "./OrderDetail";
import MainContext from "../../layouts/Main/MainContext";
import moment from "moment";
import {TextField} from "@material-ui/core";

class Order extends Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR,
      cellClass: params => {
        return params.data.operatorOperation ? 'custom-cell-red' : ''
      }
    };

    this.isAdmin = Util.isAdminUser();
    this.isZorluUser = Util.isZorluUser();
    this.robots = [];

    this.colDefs = [
      {
        headerName: 'Sipariş Id',
        field: 'orderId'
      },
      {
        headerName: 'Sipariş No',
        field: 'orderNo'
      },
      {
        headerName: 'Durum',
        field: 'status'
      },
      {
        headerName: 'Sipariş Tarihi',
        field: 'orderDate',
        cellRenderer: 'DateTimeCellRenderer',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          debounceMs: 500,
          comparator: Util.GRID_DATE_COMPARATOR
        },
        cellRendererParams: {
          format: 'DD.MM.YYYY HH:mm'
        }
      },
      {
        headerName: 'Alıcı Id',
        field: 'buyerId'
      },
      {
        headerName: 'Alıcı',
        field: 'buyer'
      },
      {
        headerName: 'Telefon',
        field: 'phone'
      },
      {
        headerName: 'Ödenen Fiyat',
        filter: 'agNumberColumnFilter',
        field: 'paidPrice',
        cellRenderer: 'CurrencyCellRenderer'
      },
      {
        headerName: 'Daire No',
        field: 'flatNo'
      },
      {
        headerName: 'Ödeme Id',
        field: 'iyzicoPaymentId',
      },
      {
        headerName: 'Robot Id',
        field: 'robotId'
      },
      {
        headerName: 'Operatör İşlemi',
        field: 'operatorOperation'
      }
    ];

    this.inputParams = {
      orderNo: {
        label: 'Sipariş No', type: 'text', defaultValue: ''
      },
      status: {   //Hazırlanıyor, RobotYolda, RobotAsansörde, RobotKapıyaGeldi, TeslimEdildi
        label: 'Durum', defaultValue: 'Hazırlanıyor', type: 'select',
        options: [
          {label: 'Tümü', value: ''},
          {label: 'Hazırlanıyor', value: 'Hazırlanıyor'},
          {label: 'Robot Yolda', value: 'Robot Yolda'},
          {label: 'Robot Asansörde', value: 'Robot Asansörde'},
          {label: 'Robot Katta', value: 'Robot Katta'},
          {label: 'Robot Kapıya Geldi', value: 'Robot Kapıya Geldi'},
          {label: 'Teslim Edildi', value: 'Teslim Edildi'},
          {label: 'Sipariş Elle Teslim Edildi', value: 'Sipariş Elle Teslim Edildi'},
          {label: 'Teslim Edilemedi', value: 'Teslim Edilemedi'}
        ]
      },
      beginDate: {
        label: 'Başlangıç Tarihi',
        defaultValue: moment(),
        type: 'datetime-custom',
        dateType: 'date',
        nullable: true
      },
      finishDate: {
        label: 'Bitiş Tarihi',
        defaultValue: moment(),
        type: 'datetime-custom',
        dateType: 'date',
        nullable: true
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: this.isZorluUser ? 2 : 1, type: 'select',
        disabled: this.isAdmin ? false : true,
        options: this.isZorluUser ? [
          {label: 'Zorlu', value: 2}
        ] : this.props.projectList
      }
    };

    this.robotOptions = [
      {label: '', value: ''},
      {label: 'Sıradaki Robot', value: 'next_robot_id'}
    ];

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      showOrderDetailModal: false,
      orderProjectId: 1,
      orderFlatNo: 0
    }
  }

  componentDidMount() {
    if (!this.isZorluUser) {
      this.mainInstanceRef = this.context;
      this.mainInstanceRef.mqttTopicCallbacks.newOrder
        .forEach(newOrder => newOrder.callbackArr.push(this.mqttMessageCallback));
    }

    this.loadData().then();
  }

  componentWillUnmount() {
    if (this.isZorluUser)
      return;

    this.mainInstanceRef.mqttTopicCallbacks.newOrder.forEach(newOrder => {
      newOrder.callbackArr = newOrder.callbackArr.filter(item => {
        return item !== this.mqttMessageCallback;
      });
    });
  }

  createRobotOption = async (projectId) => {
    this.robotOptions = [
      {label: '', value: ''},
      {label: 'Sıradaki Robot', value: 'next_robot_id'}
    ];

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/robots/get-robot-ids-by-project-id',
      'POST', Util.HEADERS_FOR_GET, projectId);
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    apiRes.robotIdList.forEach(robotId => this.robotOptions.push({label: robotId + '', value: robotId + ''}));
  }

  mqttMessageCallback = (topic, message) => {
    const messageJson = JSON.parse(message);
    const newOrderId = messageJson.newOrderId;

    if (newOrderId)
      this.loadData(newOrderId).then();
  }

  loadData = async (orderId) => {
    this.setState({loading: true});
    const form = this.state.formData;

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/robots/get-robots-by-project-id',
      'POST', Util.HEADERS_FOR_GET, form.projectId.value || 1);
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.robots = apiRes.robots;

    const body = {
      orderNo: form.orderNo.value,
      status: form.status.value,
      beginDate: form.beginDate.value,
      finishDate: form.finishDate.value,
      projectId: form.projectId.value
    };

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/order/get-orders-with-condition',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      gridRowData: this.prepareRowData(apiRes)
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();

    if (orderId) {
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.data.orderId === orderId) {
          console.debug('rowNode : ', rowNode);
          rowNode.setSelected(true);
          this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    }
  }

  createOrder = async () => {
    this.setState({loading: true});

    const body = {
      flatNo: this.isZorluUser ? 1 : this.state.orderFlatNo,
      projectId: this.isZorluUser ? 2 : this.state.orderProjectId
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/make-order-without-payment',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show('Yeni sipariş oluşturuldu!', 'Başarılı', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  excelExport = () => {
    const params = {
      fileName: "Sipariş-Listesi.xlsx",
      sheetName: 'Sipariş-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';
    return null;
  }

  onRowDoubleClicked = async (event) => {
    await this.createRobotOption(event.data.projectId);

    this.setState({
      showOrderDetailModal: true,
      selectedOrder: event.data
    });
  }

  closeModal = (orderId) => {
    this.setState({showOrderDetailModal: false});

    if (this.state.selectedOrder) {
      const orderId = this.state.selectedOrder.orderId;
      const that = this;
      this.loadData().then(function () {
          that.gridApi.forEachNode(function (rowNode, index) {
            if (rowNode.data.orderId === orderId) {
              rowNode.setSelected(true);
              that.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
            }
          })
        }
      );
    }
  }

  changeValue = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer} style={{display: 'flex', flexWrap: 'wrap', height: '150px'}}>
        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

        <Dialog
          open={this.state.showOrderDetailModal}
          onClose={this.closeModal}
          fullWidth={true}
          maxWidth={'md'}
        >
          <OrderDetail
            onClose={this.closeModal}
            orderDetail={this.state.selectedOrder}
            projectId={this.state.formData.projectId.value}
            robots={this.robots}
            getRobotOptions={() => this.robotOptions}
          />
        </Dialog>

        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />

        <div style={{display: 'flex', width: '30%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            onClick={this.loadData}
          >
            Sorgula
          </Button>
        </div>

        {(this.isAdmin || this.isZorluUser) &&
        <div style={{display: 'flex', width: '30%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            onClick={this.createOrder}
          >
            {this.isAdmin ? 'Ödemesiz Yeni Sipariş Oluştur' : '(Zorlu) Yeni Sipariş Oluştur'}
          </Button>
        </div>
        }

        {this.isAdmin &&
        <div style={{display: 'flex', width: '30%', textAlign: 'center', padding: '10px'}}>
          <TextField
            size='small'
            label="Proje Id"
            onChange={this.changeValue}
            type="number"
            name='orderProjectId'
            value={this.state.orderProjectId}
            variant="outlined"
          />
          <TextField
            style={{paddingLeft: '10px'}}
            size='small'
            label="Daire No"
            onChange={this.changeValue}
            type="number"
            name='orderFlatNo'
            value={this.state.orderFlatNo}
            variant="outlined"
          />
        </div>
        }

      </div>
      <div>
        <Toolbar handlers={
          [{callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'}]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 250px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          getRowClass={this.getRowClass}
          onRowDoubleClicked={this.onRowDoubleClicked}
          suppressContextMenu={true}
        >
        </Grid>
      </div>
    </div>
  };
}

export default Order;
