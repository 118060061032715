import React, {Component} from 'react';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import styles from './Alert.module.css'
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {connect} from 'react-redux';
import {SET_ALERT} from "../../redux/actions/actionTypes";

class Alert extends Component {
  constructor(props) {
    super(props);
  }

  createIcon() {
    if (this.props.alert.icon === 'question')
      return (<HelpIcon style={{fontSize: '50px', color: '#6a98d2'}}/>);
    else if (this.props.alert.icon === 'success')
      return (<CheckCircleIcon style={{fontSize: '50px', color: '#055f05'}}/>);
    else if (this.props.alert.icon === 'info')
      return (<InfoIcon style={{fontSize: '50px', color: '#6a98d2'}}/>);
    else if (this.props.alert.icon === 'warn')
      return (<WarningIcon style={{fontSize: '50px', color: 'rgb(206, 55, 55)'}}/>);
    else if (this.props.alert.icon === 'error')
      return (<ErrorIcon style={{fontSize: '50px', color: 'rgb(206, 55, 55)'}}/>);
  }

  createButtons() {
    const buttons = this.props.alert.buttons;
    if (buttons) {
      const buttonList = buttons.map((button, key) => {
          return <Button variant='contained' color='default' style={{marginRight: '5px', bottom: '4px'}} size="small"
                         onClick={button.handler === undefined ? this.props.closeHandler : () => {
                           button.handler();
                           this.props.closeHandler();
                         }} key={key}>
            {button.text}
          </Button>
        }
      )
      return buttonList;
    }
  }

  createTitle() {
    if (this.props.alert.title)
      return this.props.alert.title;
    else
      return 'Uyarı';
  }

  render() {
    if (!this.props.alert)
      return null;

    return (
      <Modal
        open={true}
        onClose={this.props.closeModal}
        style={{zIndex: '9999'}}>
        <div
          onKeyDown={(e)=>{
            if(e.key==='Esc')
              alert('Esc');
          }
          }
          className={styles.modalContent}
             style={{
               top: 'calc(50% - 150px)',
               left: 'calc(50% - 200px)',
               width: '350px'
             }}>
          <div className={styles.modalHeader}>
            <div className={styles.headerCloseButtonContainer}>
            <span className={styles.alertHeaderText}>
              {this.createTitle()}
            </span>
            </div>
          </div>
          <div style={{minHeight: '160px'}}>
            <div className={styles.alertBodyMainDiv}>
              {this.props.alert.icon &&
              <div className={styles.alertIconDiv}>
                {this.createIcon()}
              </div>
              }
              <div className={styles.alertMessageDiv}>
                {this.props.alert.message}
              </div>
            </div>
            <hr style={{borderStyle: 'outset'}}/>
            <div className={styles.alertButtonDiv}>
              {this.createButtons()}
            </div>
          </div>
        </div>
      </Modal>
    )
  };

}

const mapDispatchToProps = (dispatch) => {
  // console.log('(Alert.js) mapDispatchToProps');
  return {
    closeModal: () => {
      dispatch({
        type: SET_ALERT,
        alert: null
      })
    }
  };
};

export default connect(null, mapDispatchToProps)(Alert);
