import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import RobotRemoteContextProvider from 'components/RobotRemoteProject/RobotRemoteContext/RobotRemoteContext';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundColor: 'black',
    color: 'white'
  },
  content: {
    height: '100%'
  }
}));

const RobotLayout = props => {
  const { children } = props;
  document.body.style.backgroundColor = 'black';
  const classes = useStyles();

  return (
    <RobotRemoteContextProvider>
      <div className={classes.root}>
        <main className={classes.content}>{children}</main>
      </div>
    </RobotRemoteContextProvider>
  );
};

RobotLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default RobotLayout;
