import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { rrLanguage } from '../Utils/rrLanguage';
import { RobotRemoteContext } from '../RobotRemoteContext/RobotRemoteContext';
import LoginUtil from '../../../utils/LoginUtil';
import { Divider, Hidden, List, ListItem, SwipeableDrawer } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import InputIcon from '@material-ui/icons/Input';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '85px',
    backgroundColor: 'grey'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  header : {
    display:'flex',
    justifyContent:'space-between'
  },
  color: {
    backgroundColor: '#317FFB'
  },
  profileName: {
    width: 'auto',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginRight: '15px',
    cursor: 'pointer'
  },
  projectName: {
    width: 'auto',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginLeft: '10px',
    paddingLeft: '10px'
  },
  logoutButton: {
    
  }
}));

export default function RobotHeader(props) {
  const { lang, setLang } = useContext(RobotRemoteContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(false)
  const languageSet = value => {
    sessionStorage.setItem('language', value);
    setLang(value);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profile = LoginUtil.getProfile();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" classes={{ root: classes.color }}>
        <Toolbar classes={{ root: classes.header }}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu">
            <MenuIcon onClick={() => setOpenMenu(true)} />
          </IconButton>
          <Hidden xsDown>
            <Typography variant="h6" className={classes.title}></Typography>
            {/* <Typography variant="h6" className={classes.title}>
              <ButtonGroup
                color="primary"
                variant="contained"
                aria-label="outlined secondary button group">
                {props.robots.map((robot, index) => {
                  return (
                    <Button
                      key={index}
                      color={props.robotId == robot ? 'primary' : 'secondary'}
                      onClick={() => {
                        props.setRobotId(robot);
                        props.setLidState({
                          lt: false,//left top lid
                          rt: false,//right top lid
                          lb: false,//left bottom lid
                          rb: false,//right bottom lid
                        })
                      }}>
                      {robot}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Typography> */}
            <ButtonGroup
              color="primary"
              variant="contained"
              aria-label="outlined secondary button group">
              <Button
                value="en"
                color={lang == 'en' ? 'primary' : 'secondary'}
                onClick={() => languageSet('en')}>
                EN
              </Button>
              <Button
                value="tr"
                color={lang == 'tr' ? 'primary' : 'secondary'}
                onClick={() => languageSet('tr')}>
                TR
              </Button>
            </ButtonGroup>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              open={open}
              onClose={handleClose}>
              <MenuItem onClick={handleClose}>
                <ul style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                  padding: '0 15px',

                }}>
                  <h4 style={{
                    padding: '0',
                    margin: '0',
                  }}
                  >Roles :</h4>
                  {
                    profile.projectRoles &&
                    profile.projectRoles.map((element, key) => {
                      return <li key={key}>{element.role}</li>
                    })
                  }
                </ul>

              </MenuItem>
              <MenuItem onClick={handleClose}>{profile.email}</MenuItem>
            </Menu>
            <Typography
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              className={classes.profileName}>
              {profile.name}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography className={classes.projectName}>
              {props.activeProjectName != null ? props.activeProjectName : null}
            </Typography>
          </Hidden>
          <IconButton
            color="inherit"
            title={rrLanguage.exit}
            className={classes.logoutButton}
            onClick={() => LoginUtil.logout()}
          >
            <InputIcon/>
          </IconButton>
        </Toolbar>

        <SwipeableDrawer
          open={openMenu}
          onOpen={() => setOpenMenu(true)}
          onClose={() => setOpenMenu(false)}
        >
          <IconButton onClick={() => setOpenMenu(false)} sx={{ backgroundColor: '#000' }}>
            <ChevronLeft />
          </IconButton>
          <Divider />

          <List style={{ backgroundColor: "#232323", height: '100%' }}>
            <Hidden smUp>      
              <ListItem>
                <Typography className={classes.profileName}>
                  {profile.name} | {props.activeProjectName != null ? props.activeProjectName : null}
                </Typography>
              </ListItem>
              <ListItem>
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    padding: '0 15px',

                  }}
                >
                  {
                    profile.projectRoles &&
                    profile.projectRoles.map((element, key) => {
                      return <li style={{ color: '#fff' }} key={key}>{element.role}</li>
                    })
                  }
                </ul>
              </ListItem>

              <ListItem>
                <Typography className={classes.profileName}>
                  {profile.email}
                </Typography>
              </ListItem>

              <ListItem>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  aria-label="outlined secondary button group">
                  <Button
                    value="en"
                    color={lang == 'en' ? 'primary' : 'secondary'}
                    onClick={() => languageSet('en')}>
                    EN
                  </Button>
                  <Button
                    value="tr"
                    color={lang == 'tr' ? 'primary' : 'secondary'}
                    onClick={() => languageSet('tr')}>
                    TR
                  </Button>
                </ButtonGroup>
              </ListItem>
            </Hidden>   
            <ListItem>
              <Typography variant="h6" className={classes.title}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  aria-label="outlined secondary button group">
                  {props.robots.map((robot, index) => {
                    return (
                      <Button
                        key={index}
                        color={props.robotId == robot ? 'primary' : 'secondary'}
                        onClick={() => {
                          props.setRobotId(robot);
                          props.setLidState({
                            lt: false,//left top lid
                            rt: false,//right top lid
                            lb: false,//left bottom lid
                            rb: false,//right bottom lid
                          })
                        }}>
                        {robot}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Typography>
            </ListItem>

          </List>
        </SwipeableDrawer>
      </AppBar>
    </div>
  );
}
