const topicInfo = {
  NavHistory: { apiName: 'nav-history', functionName: 'setNavHistory' },
  LivePose: { apiName: 'live_pose', functionName: 'setLivePose' },
  State: { apiName: 'state', functionName: 'setRobotState' },
  ElevInfo: { apiName: 'elev_info', functionName: 'setElevInfo' },
  CommStatus: { apiName: 'comm_status', functionName: 'setCommStatus' },
  ErStop: { apiName: 'erstop', functionName: 'setErstop' },
  EnableCmdxz: { apiName: 'enable_cmd_xz', functionName: 'setEnableCmdxz' },
  LiveStream: { apiName: 'live_stream', functionName: 'setLiveStream' },
  EnableCmdxzAssignedUserId: { apiName: 'enable_cmd_xz_assigned_uid', functionName: 'setEnableCmdxzAssignedUserId' }
};

const modals = {
  RobotState: 'RobotState',
  ManualControl: 'ManualControl',
  Estop: 'Estop',
  NavHistory: 'NavHistory'
};

const wsConnectionStatus = {
  CONNECTING:0,
  OPEN:1,
  CLOSING:2,
  CLOSED:3
};
export { topicInfo, modals , wsConnectionStatus };
