import React, {Component} from 'react';
import styles from './Toolbar.module.css'
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader";
import ModalBody from "../Modal/ModalBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalFooter from "../Modal/ModalFooter";
import Button from "@material-ui/core/Button";
import Util from "../../utils/CommonUtil";
import {AgGridReact} from "@ag-grid-community/react";
import Mesaj from "../../utils/Mesaj";
import {connect} from "react-redux";


function gridCheckRenderer(props) {

  let checked;
  const sutunlar = this.state.gorunumAyarlari.sutunlar;
  for (let i = 0; i < sutunlar.length; i++) {
    if (sutunlar[i].field === props.data.field) {
      checked = sutunlar[i].show;
      break;
    }
  }

  return <input
    type="checkbox"
    name={props.data.field}
    disabled={props.data.disabled}
    onChange={this.gridCheckChanged.bind(this, props.data)}
    checked={checked}/>;
}

class Toolbar extends Component {

  constructor(props) {


    //this.params yerine this.props.inputParams
    super(props);

    this.gridColumnDefs = [
      {
        headerName: "Sütun",
        field: "headerName",
        rowDrag: props.gorunumAyarlari && props.gorunumAyarlari.reorder,
        flex: 4,
        cellClass: 'alignRight'
      },
      {
        headerName: "Görünsün",
        cellRenderer: 'gridCheckRenderer',
        flex: 1
      },

    ]

    this.state = {
      loading: false,
      showModal: false,
      gorunumAyarlari: null,
      gorunumTercihiVar: false
    }
  }

  componentDidMount() {
    if (this.props.gridId) {
      this.gorunumAyarlariSorgula();
    }

  }

  gridCheckChanged = (sutunTanim, event) => {

    let gorunumAyarlari = {...this.state.gorunumAyarlari};

    for (let i = 0; i < gorunumAyarlari.sutunlar.length; i++) {
      if (gorunumAyarlari.sutunlar[i].field === sutunTanim.field) {
        gorunumAyarlari.sutunlar[i].show = event.target.checked;
        break;
      }
    }

    this.setState({gorunumAyarlari: gorunumAyarlari});
    this.gridApi.redrawRows();
  }

  prepareButton(item, index, leftSeperator) {

    if (!item.type) {
      let calculatedLeftSeperator;
      if (leftSeperator === true || leftSeperator === false) {
        calculatedLeftSeperator = leftSeperator;
      } else {
        calculatedLeftSeperator = (index !== 0);
      }
      return this.prepareSingleButton(item, index, calculatedLeftSeperator);
    } else if (item.type === 'group') {
      return item.subHandlers.map((item, index) => {
        return this.prepareButton(item, index, index === 0)
      });
    } else if (item.type === 'gorunumDialogAc') {
      item.callBack = this.showModal.bind(this);
      return this.prepareSingleButton(item, index, leftSeperator);
    } else if (item.type === 'gorunumSifirla') {
      item.callBack = this.gorunumSifirla;
      return this.prepareSingleButton(item, index, leftSeperator);
    } else if (item.type === 'gorunumTercihKaydet') {
      item.callBack = this.gorunumTercihKaydet;
      return this.prepareSingleButton(item, index, leftSeperator);
    } else {
      console.error("Toolbar öğesinde bilinmeyen type gelmiştir:" + item.type, ' Item:', item);
    }

  }

  showModal = (event) => {
    const gridApis = this.props.getGridApis();
    //Ag-grid sonradan hazır olabiliyor. Eğer gridApi hazır değilse yapılacak bir işlem yok çıkılmalıdır.
    if (gridApis && gridApis.api) {
      gridApis.api.stopEditing();
    }

    this.setState({showModal: true});

  }

  hideModal = () => {
    this.setState({showModal: false});
  }

  prepareSingleButton(item, index, leftSeparator) {
    return <div key={index} onClick={item.callBack} title={item.title}>
      {leftSeparator && <div className={styles.separatorDiv}></div>}
      <img src={item.icon}/>
    </div>;
  }

  handleModalValueChange = (event) => {
    console.log('handleModalValueChange event', event);

    let gorunumAyarlari = {...this.state.gorunumAyarlari};
    gorunumAyarlari[event.target.name].checked = event.target.checked;
    this.setState({gorunumAyarlari: gorunumAyarlari});
  }

  onGridReady = params => {
    this.gridApi = params.api;
  }

  gridRowDragEnd = (event) => {
    //let movingNode = event.node;
    //let overNode = event.overNode;
    //console.log('gridRowDragEnd movingNode:', movingNode.data ,  " overNode:",overNode);

    let sira = 1;
    let gorunumAyarlari = {...this.state.gorunumAyarlari};

    this.gridApi.forEachNode(function (rowNode, index) {

      for (let i = 0; i < gorunumAyarlari.sutunlar.length; i++) {
        if (gorunumAyarlari.sutunlar[i].field === rowNode.data.field) {
          gorunumAyarlari.sutunlar[i].sira = sira;
          break;
        }
      }
      sira++;
    });

    this.setState({gorunumAyarlari: gorunumAyarlari});
    this.gridGorunumGuncelle(gorunumAyarlari)
  }

  prepareGridRowData = () => {
    //Kaydedilen ayar sonrasında yeni grid eklendi ise seçeneklerde gözükmesi için props ile gelen ana sütun listesi dönülecek.
    const gridRows = [];
    for (let i = 0; i < this.props.tumSutunlar.length; i++) {
      const sutun = this.props.tumSutunlar[i];
      let eklenecekSutun;
      for (let j = 0; j < this.state.gorunumAyarlari.sutunlar.length; j++) {
        if (sutun.field === this.state.gorunumAyarlari.sutunlar[j].field) {
          eklenecekSutun = this.state.gorunumAyarlari.sutunlar[j];
          break;
        }
      }
      if (!eklenecekSutun) {
        eklenecekSutun = sutun;
      }
      gridRows.push(eklenecekSutun);
    }
    return gridRows;
  }

  async gorunumAyarlariSorgula() {
    this.setState({loading: true});
    let companyId = this.props.selectedCompany.companyId;

    let body = {
      procedure: false,
      schema: 0,
      methodName: "web_lg_get_grid_ayar",
      parameters: [companyId, this.props.gridId]
    };
    let ret = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/core/sp/lg', 'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    if (ret.donenKod !== 0) {
      this.setState({loading: false});
      Mesaj.show(ret.donenMesaj, 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    let yeniGorunumAyarlari;
    let gorunumTercihiVar;
    if (ret.result) {
      yeniGorunumAyarlari = JSON.parse(ret.result[0]);
      gorunumTercihiVar = true;
    } else {
      if (!this.props.defultGorunumAyarlari) {
        throw new Error('Toolbar öğesi için defultGorunumAyarlari prop boştur.');
      }
      yeniGorunumAyarlari = {...this.props.defultGorunumAyarlari};
      gorunumTercihiVar = false;
    }

    this.setState({loading: false, gorunumTercihiVar: gorunumTercihiVar, gorunumAyarlari: yeniGorunumAyarlari});
    this.gridGorunumGuncelle(yeniGorunumAyarlari);
  }

  gridGorunumGuncelle = (yeniGorunumAyarlari) => {
    const gridApis = this.props.getGridApis();
    //Ag-grid sonradan hazır olabiliyor. Eğer gridApi hazır değilse yapılacak bir işlem yok çıkılmalıdır.
    if (!gridApis || !gridApis.api || !gridApis.columnApi) {
      return;
    }

    const gorunumAyarlari = yeniGorunumAyarlari ? yeniGorunumAyarlari : this.state.gorunumAyarlari;

    // sutunlariSigdir: {disabled:false, checked:true, text:'Sütunları Tabloya Sığdır' },
    // gruplamaSatiriGorunsun: {disabled:true, checked:false,text:'Gruplama Satırı Görünsün' },
    // filtreSatiriGorunsun: {disabled:true, checked:false, text:'Filtre Satırı Görünsün'},
    // filtreBilgileriSaklansin: {disabled:true, checked:false, text:'Filtre Bilgileri Saklansın'},
    //sutunlar:this.editableGridSutunlar

    const visibleColIdList = [];
    const nonVisibleColIdList = [];

    const siraliSutunListesi = [];

    gorunumAyarlari.sutunlar.sort(Util.compareByField.bind(null, 'sira'));

    for (let j = 0; j < gorunumAyarlari.sutunlar.length; j++) {
      const fieldName = gorunumAyarlari.sutunlar[j].field;
      gorunumAyarlari.sutunlar[j].show ? visibleColIdList.push(fieldName) : nonVisibleColIdList.push(fieldName);
      siraliSutunListesi.push(fieldName);
      if (gorunumAyarlari.sutunlar[j].width) {
        gridApis.columnApi.setColumnWidth(fieldName, gorunumAyarlari.sutunlar[j].width);
      }
    }

    gridApis.columnApi.setColumnsVisible(visibleColIdList, true);
    gridApis.columnApi.setColumnsVisible(nonVisibleColIdList, false);
    gridApis.columnApi.moveColumns(siraliSutunListesi, 0);

    //TODO sütunları sığdır, çalışıyormu
    if (gorunumAyarlari.sutunlariSigdir && gorunumAyarlari.sutunlariSigdir.checked) {
      gridApis.api.sizeColumnsToFit();
    }

    //const allColumns=this.editableGridColumnApi.getAllGridColumns();
    //for(let i=0;i<allColumns.length;i++) {}
  }

  gorunumTercihKaydet = async () => {
    this.setState({loading: true});
    let companyId = this.props.selectedCompany.companyId;

    const gorunumAyarlari = {...this.state.gorunumAyarlari};
    const cols = this.props.getGridApis().columnApi.getAllDisplayedColumns();
    for (let i = 0; i < cols.length; i++) {
      console.log('col:', cols[i].actualWidth);

      for (let j = 0; j < gorunumAyarlari.sutunlar.length; j++) {
        if (gorunumAyarlari.sutunlar[j].field === cols[i].colDef.field) {
          gorunumAyarlari.sutunlar[j].width = cols[i].actualWidth;
          break;
        }
      }
    }

    let body = {
      procedure: false,
      schema: 0,
      methodName: this.state.gorunumTercihiVar ? 'web_lg_upt_grid_ayar' : 'web_lg_ins_grid_ayar',
      parameters: [companyId, this.props.gridId, JSON.stringify(this.state.gorunumAyarlari)]
    };
    let ret = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/core/sp/lg', 'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    if (ret.donenKod !== 0) {
      this.setState({loading: false});
      Mesaj.show(ret.donenMesaj, 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
    } else {
      this.setState({loading: false});
      Mesaj.show('Görünüm Tercihi başarı ile kaydedildi', 'Başarılı', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
    }
  }

  gorunumSifirla = () => {
    const defaultGorunumAyarCopy = JSON.parse(JSON.stringify(this.props.defultGorunumAyarlari));
    this.setState({gorunumAyarlari: defaultGorunumAyarCopy});
    this.gridGorunumGuncelle(defaultGorunumAyarCopy);
  }

  okClicked = () => {
    this.gridGorunumGuncelle();
    this.hideModal();
  }

  render() {
    return (
      <div>
        {this.state.loading && <CircularProgress style={{top: "50%", left: "50%", position: "absolute"}}/>}
        <div className={styles.toolBarContainer}>
          {
            this.props.handlers.map((item, index) => {
              return this.prepareButton(item, index)
              /*return <div key={index} onClick={this.props.handlers[key]}>{this.prepareButton(key)}</div>;*/
            })
          }
        </div>
        {this.state.gorunumAyarlari &&
        <Modal open={this.state.showModal} zIndex={this.zIndex} closeHandler={this.hideModal} top="calc(50% - 360px)"
               height="520px" left="calc(50% - 220px)" width="440px">
          <ModalHeader><span style={{fontSize: '17px', fontWeight: '600'}}>Sütun Görünüm Ayarları</span></ModalHeader>
          <ModalBody>
            <div className={styles.checkboxContainer}>
              {
                Object.keys(this.state.gorunumAyarlari).map((key, index) => {
                  if (key === 'reorder' || Array.isArray(this.state.gorunumAyarlari[key]))
                    return null;

                  return <div key={index}>
                    <label style={{color: Util.isTrue(this.state.gorunumAyarlari[key].disabled) ? 'gray' : 'inherit'}}
                    >
                      <input
                        type="checkbox"
                        name={key}
                        disabled={this.state.gorunumAyarlari[key].disabled}
                        onChange={this.handleModalValueChange}
                        checked={this.state.gorunumAyarlari[key].checked}/>
                      {this.state.gorunumAyarlari[key].text}
                    </label></div>
                })
              }
            </div>

            <div
              className="ag-theme-balham"
              style={{
                height: 'calc(100% - 120px)',
                width: '100%',
                marginTop: '8px'
              }}
            >
              <AgGridReact
                columnDefs={this.gridColumnDefs}
                rowData={this.prepareGridRowData()}
                rowSelection='single'
                animateRows={true}
                rowDragManaged={true}
                onGridReady={this.onGridReady}
                frameworkComponents={{gridCheckRenderer: gridCheckRenderer.bind(this)}}
                onRowDragEnd={this.gridRowDragEnd}
              >
              </AgGridReact>
            </div>

          </ModalBody>
          <ModalFooter>
            <div style={{textAlign: "right"}}>
              <Button style={{marginRight: "10px", width: "80px"}}
                      variant="contained" color="secondary"
                      onClick={this.okClicked}>Tamam</Button>
              <Button style={{width: "80px"}} variant="contained" color="secondary"
                      onClick={this.hideModal}>Vazgeç</Button>
            </div>
          </ModalFooter>
        </Modal>}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('(Toolbar.js) mapStateToProps state : ', state);
  return {
    selectedCompany: state.selectedCompany
  };
}

export default connect(mapStateToProps)(Toolbar);
