import React, {Component} from 'react';
import styles from "./PopModal.module.css"
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader";
import ModalBody from "../Modal/ModalBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalFooter from "../Modal/ModalFooter";
import Util from "../../utils/CommonUtil";
import PopGrid from "./PopGrid";
import Button from "@material-ui/core/Button";
import {PageAndMenuUtil} from "../../utils/PageAndMenuUtil";
import Mesaj from "../../utils/Mesaj";
import {Consts} from "../../const/Consts";


class PopModal extends Component {

  constructor(props) {
    super(props);
    this.params = props.dynamicPopModalParams ? {...props.paramCallback(props), ...props} : props;
    this.gridRef = null;

    this.gridApi = null;
    this.gridColumnApi = null;

    this.state = {
      loading: Util.isTrue(this.params.lazy) ? true : false,
      gridRowData: this.params.rowData ? this.params.rowData : [],
      customPopCompState: []
    }

    this.zIndex = 3000;
    const that = this;
    this.dataSource = {
      rowCount: null,
      getRows: function (params) {
        console.log("asking for " + params.startRow + " to " + params.endRow);
        //let lazyModel= {startRow: params.startRow,endRow:params.endRow, sortModel:params.sortModel,filterModel:params.filterModel};
        //console.log("getRows params", params );
        setTimeout(function () {
          that.loadFromUrl(params, params.successCallback, params.failCallback).then();
        }, 1);

      }
    };

    //statik data gelmemişse
    if (!this.params.rowData) {
      if (!Util.isTrue(this.params.lazy)) {
        this.loadFromUrl(null, null).then();
      }
    }

    //Event olarak dinlenilecek tuş listesi hazırlanıyor. CustomComponenetlerin içindekiler bilinmediği için döngü ile oluşturulacak.
    this.keyEventCallbackMap = this.prepareKeyEventCallBacks();

  }

  prepareKeyEventCallBacks() {
    let keyMap = new Map();

    //Escape düğmesi modal içinden dinlendiği için burada herhangi bir eklemeye gerek yok.
    keyMap.set(13, this.onGridRowSelection);

    if (!this.params.customComponents || this.params.customComponents.length === 0) {
      return keyMap;
    }

    this.params.customComponents.map((customComp, index) => {
      if (customComp.key) {
        keyMap.set(customComp.key.keyCode, this.setCustomCompState.bind(this, customComp));
      }
    })

    return keyMap;
  }

  selectFirstRow() {

    let firstDisplayedCol = this.gridColumnApi.getAllDisplayedColumns()[0];

    this.gridApi.ensureColumnVisible(firstDisplayedCol);
    this.gridApi.setFocusedCell(0, firstDisplayedCol.colId, null);

    /*Alttaki işlemler PopGrid içinden direkt yapılıyor zaten
    const that=this;
    this.gridApi.forEachNode(function (rowNode, index) {
      if(index!==0)
        return;
        rowNode.setSelected(true);
      // sets focus into the first grid cell
      that.gridApi.setFocusedCell(rowNode.rowIndex, firstEditCol);

    });
    */


  }

  setGridRef(gridRef) {
    this.gridRef = gridRef;
  }


  async loadFromUrl(dataSourceParams, successCallback, failCallback) {
    let token = sessionStorage.getItem('token');
    let headers = {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + token
    };

    let body = {methodName: this.params.urlMethodName};
    if (this.params.urlSchema === 0 || this.params.urlSchema) {
      body.schema = this.params.urlSchema;
    }
    body.parameters = [];

    if(this.params.urlParametersFunc)
      body.parameters = this.params.urlParametersFunc();
    else if (this.params.urlParameters)
      body.parameters = [...this.params.urlParameters];

    if (Util.isTrue(this.params.lazy)) {
      body.parameters.push(dataSourceParams.startRow);
      body.parameters.push(dataSourceParams.endRow);
      body.parameters.push(JSON.stringify(dataSourceParams.sortModel));
      body.parameters.push(JSON.stringify(dataSourceParams.filterModel));
    }

    let endPoint=process.env.REACT_APP_API_GATEWAY_URL + '/core/sp/lg';
    if(this.params.urlEndPoint)
      endPoint=process.env.REACT_APP_API_GATEWAY_URL + this.params.urlEndPoint;

    let sendType='POST';
    if(this.params.urlSendType)
      sendType=this.params.urlSendType;

    let toBeSendBody= JSON.stringify(body);
    if(sendType==='GET')
      toBeSendBody=null;

    let apiRes = await Util.apiRequest(endPoint, sendType, headers, toBeSendBody);

    if (apiRes.resultMessage && apiRes.resul!==Consts.RESULT_SUCCESS) {
      console.error("PopModal bir hata oluştu! Detay:", apiRes, "PopModal(this):", this);
      if (failCallback) {
        failCallback();
      }

      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage, 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    //TODO props üzerinden sütun filtreleme geliştirilecek.
    if (Util.isTrue(this.params.lazy)) {
      successCallback(apiRes.result ? apiRes.result : [], apiRes.totalCount);
      this.setState({loading: false});
    } else {
      //Orj.       this.setState({loading: false, gridRowData: apiRes.result});
      this.setState({loading: false, gridRowData: apiRes});
    }

    this.selectFirstRow();

  }

  /*Alttaki methodlar Ag-Grid cell-editor olarak kullanılınca çağrılıyor*/
  getValue() {
    return this.params.data[this.params.colDef.field];
  }

  isPopup() {
    return true;
  }

  isCancelBeforeStart() {
    console.log('isCancelBeforeStart')
  }

  isCancelAfterEnd() {
    console.log('isCancelAfterEnd')
  }

  afterGuiAttached() {
    console.log('afterGuiAttached')
  }


  closeWithCancel = () => {
    this.close(true);
  }

  close = (cancelEdit) => {
    console.log('PopModal Close. cancelEdit:' + cancelEdit);
    if (this.params.closeHandler) {
      this.params.closeHandler();
    } else if (this.params.api.stopEditing) {
      //PopModal X düğmesi veya Cancel düğmesine basınca stopEditing içindeki cancel parametresi true olarak gidiyor böylece value'yu değiştirmeyecektir.
      if (cancelEdit === true) {
        this.params.api.stopEditing(true);
      } else {
        this.params.api.stopEditing();
      }

    } else {
      console.error("PopModal için Close handler metotu tanımlanmamıştır.");
    }
  }

  onGridRowSelection = () => {
    let selectedRow = this.gridRef.gridApi.getSelectedRows();
    if (!selectedRow || selectedRow.length === 0) {
      this.setState({loading: false});
      Mesaj.show('Seçim yapılmadı!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }
    this.close(false);
    this.params.onSelection(this.params, selectedRow);
  }

  setCustomCompState = (customComponent) => {
    let customPopCompState = [];
    if (customComponent) {
      customPopCompState.push(customComponent);
    }
    this.setState({customPopCompState: customPopCompState});

    //CustomComponent kapatılırsa, alttaki metot ile lazy olan PopModal için sorgu yenilensin.
    if (!customComponent && !this.params.rowData) {
      if (Util.isTrue(this.params.lazy)) {
        //const getRowsParams = {startRow:0,endRow:20, sortModel: [], filterModel: {}  };
        this.gridRef.gridOptions.api.setDatasource(this.dataSource);
      }
    }
  }

  prepareCustomComponents() {
    if (!this.params.customComponents || this.params.customComponents.length === 0) {
      return null;
    }

    const customComponentButtons = this.params.customComponents.map((customComp, index) => {
      return <Button
        onClick={() => this.setCustomCompState(customComp)}
        key={index}
        style={{marginRight: "10px"}}
        variant="contained"
        color="secondary"
      >{customComp.buttonText}
        <br/>{customComp.key && customComp.key.keyDesc ? '(' + customComp.key.keyDesc + ')' : null} </Button>;
    })

    return customComponentButtons;
  }

  listCustomPopComponents() {
    if (!this.state.customPopCompState) {
      return null;
    }

    //console.log('this.state.customPopCompState',this.state.customPopCompState);
    const customComponents = this.state.customPopCompState.map((customComp, index) => {
      const props = {closeHandler: this.setCustomCompState, key: index};
      return PageAndMenuUtil.getComponentByName(customComp.name, props)
    })
    return customComponents;
  }


  gridApiSetter = (gridApi, gridColumnApi) => {
    this.gridApi = gridApi;
    this.gridColumnApi = gridColumnApi;
    this.selectFirstRow();
  }

  render() {
    return (
      <div>
        <Modal
          open={true}
          zIndex={this.zIndex + 200}
          closeHandler={this.closeWithCancel}
          {...this.params.modalStyle}
          keyEventCallbackMap={this.keyEventCallbackMap}
        >
          <ModalHeader><span style={{fontSize: '17px', fontWeight: '600'}}>{this.params.title}</span></ModalHeader>
          <ModalBody>
            <div> {this.listCustomPopComponents()} </div>
            {this.state.loading && <CircularProgress style={{top: "50%", left: "50%", position: "absolute"}}/>}
            <PopGrid
              popModalProps={this.params}
              currentComponentSetter={this.setGridRef.bind(this)}
              lazy={this.params.lazy}
              dataSource={this.dataSource}
              columnDefs={this.params.columnDefs}
              onSelection={this.onGridRowSelection}
              rowData={this.state.gridRowData}
              gridApiSetter={this.gridApiSetter}
            />

          </ModalBody>
          <ModalFooter>
            <div className={styles.footer}>
              <div style={{display: 'flex', width: '30%'}}>

                {this.prepareCustomComponents()}
              </div>
              <div style={{width: '70%', textAlign: 'right'}}>

                <Button style={{marginRight: "10px", width: "80px"}} variant="contained" color="secondary"
                        onClick={this.onGridRowSelection}>Tamam (Enter)</Button>

                <Button style={{marginRight: "10px", width: "80px"}} variant="contained" color="secondary"
                        onClick={this.closeWithCancel}>Vazgeç (Esc)</Button>

              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }


}

export default PopModal;
