import React, {Component} from 'react';
import styles from './User.module.css'
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import LoginUtil from "../../utils/LoginUtil";
import {DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import MainContext from "../../layouts/Main/MainContext";
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";

class User extends Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.isAdmin = Util.isAdminUser();

    this.colDefs = [
      {
        headerName: 'Id',
        field: 'id',
      },
      {
        headerName: 'Ad',
        field: 'name'
      },
      {
        headerName: 'Soyad',
        field: 'lastname'
      },
      {
        headerName: 'e-Posta',
        field: 'email'
      },
      {
        headerName: 'Pasif',
        field: 'deleted',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Oluştruma Tarihi',
        field: 'createdAt',
        cellRenderer: 'DateTimeCellRenderer',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          debounceMs: 500,
          comparator: Util.GRID_DATE_COMPARATOR
        },
        cellRendererParams: {
          format: 'DD.MM.YYYY HH:mm'
        }
      },
      {
        headerName: 'Rol',
        field: 'role'
      },
      {
        headerName: 'Telefon',
        field: 'phone'
      },
      {
        headerName: 'Sms Deneme Sayısı',
        filter: 'agNumberColumnFilter',
        field: 'smsCodeTry'
      },
      {
        headerName: 'Sms Onayı',
        field: 'smsValidated',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Proje Id',
        filter: 'agNumberColumnFilter',
        field: 'projectId'
      },
      {
        headerName: 'Daire No',
        filter: 'agNumberColumnFilter',
        field: 'flatNo'
      },
      {
        headerName: 'Toplam Sipariş Adeti',
        filter: 'agNumberColumnFilter',
        field: 'orderCount'
      },
      {
        headerName: 'Toplam Sipariş Tutarı',
        filter: 'agNumberColumnFilter',
        field: 'orderAmount'
      }
    ];

    this.inputParams = {
      name: {
        label: 'Ad', type: 'text', defaultValue: ''
      },
      lastname: {
        label: 'Soyad', type: 'text', defaultValue: ''
      },
      email: {
        label: 'E-Posta', type: 'text', defaultValue: ''
      },
      phone: {
        label: 'Telefon', type: 'text', defaultValue: ''
      },
      password: {
        label: 'Şifre',
        type: 'text',
        defaultValue: '',
        disable: this.state ? (this.state.mode === 'save' ? false : true) : false
      }, yetki: {
        label: 'Yetki',
        type: 'select',
        defaultValue: 'admin',
        disable: this.state ? (this.state.mode === 'save' ? false : true) : false,
        options: this.isAdmin ?
          [
            {label: 'Admin', value: 'admin'},
            {label: 'Moderatör', value: 'moderator'},
            {label: 'Mobil', value: 'mobile_app_user'},
            {label: 'Asansör', value: 'elevator_user'},
            {label: 'Zorlu', value: 'zorlu_user'}
          ]
          :
          [{label: 'Mobil', value: 'mobile_app_user'}]
      },
      flatNo: {
        label: 'Daire No', defaultValue: 1, type: 'number'
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: this.isAdmin ? false : true,
        options: this.props.projectList
      }
    };

    this.state = {
      loading: false,
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      showPasswordDialog: false,
      showCouponDialog: false,
      selectedCouponId: '',
      newPassword: '',
      newPasswordAgain: '',
      selectedUserId: '',
      updateUserName: '',
      updateUserLastname: '',
      updateUserEmail: '',
      updateUserPhone: '',
      updateUserFlatNo: '',
      updateUserProjectId: Util.getProjectId(),
      updateUserRole: '',
      showUpdatedDialog: false
    };

    this.coupons = [];
  }

  async componentDidMount() {
    this.mainInstanceRef = this.context;
    this.mainInstanceRef.mqttTopicCallbacks.newUser.forEach(newUser => newUser.callbackArr.push(this.mqttMessageCallback));
    this.loadData().then();
  }

  componentWillUnmount() {
    this.mainInstanceRef.mqttTopicCallbacks.newUser.forEach(newUser => {
      newUser.callbackArr = newUser.callbackArr.filter(item => {
        return item !== this.mqttMessageCallback;
      });
    });
  }

  mqttMessageCallback = (topic, message) => {
    const messageJson = JSON.parse(message);
    const newUserId = messageJson.newUserId;

    if (newUserId)
      this.loadData(newUserId).then();
  }

  loadData = async (userId) => {
    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/user/get-users', 'POST',
      Util.HEADERS_FOR_GET, this.state.formData.projectId.value);
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      gridRowData: this.prepareRowData(apiRes)
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();

    if (userId) {
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.data.id === userId) {
          rowNode.setSelected(true);
          this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    }

    this.setState({loading: true});
    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL +
      '/coupon/list-for-panel-not-easy-coupon-by-project-id',
      'Post', Util.HEADERS_FOR_GET, this.state.formData.projectId.value);
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    let coupons = [];
    apiRes.forEach(coupon => {
      coupons.push({couponId: coupon.couponId, name: coupon.name});
    });

    const options = coupons.map((option, index) => {
      return (<option
        key={index}
        value={option.couponId}
      >{option.name}</option>);
    });

    this.setState({selectedCouponId: coupons[0]?.couponId});
    this.coupons = options;
  }

  excelExport = () => {
    const params = {
      fileName: "Kullanici-Listesi.xlsx",
      sheetName: 'Kullanici-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';
    return null;
  }

  editProduct = editData => {
    this.setState({
      showUpdatedDialog: true,
      selectedUserId: editData.id,
      updateUserName: editData.name ? editData.name : '',
      updateUserLastname: editData.lastname ? editData.lastname : '',
      updateUserEmail: editData.email ? editData.email : '',
      updateUserPhone: editData.phone ? editData.phone : '',
      updateUserFlatNo: editData.flatNo ? editData.flatNo : 0,
      updateUserProjectId: editData.projectId ? editData.projectId : 0,
      updateUserRole: editData.role ? editData.role : 'mobile_app_user'
    });
  }

  getContextMenuItems = params => {
    if (params.node.group === true)
      return null;

    const menu = [
      {
        name: 'Şifre Değiştir',
        action: () => {
          this.setState({showPasswordDialog: true, selectedUserId: params.node.data.id});
        }
      },
      {
        name: 'Kullanıcı Pasif Yap',
        disabled: params.node.data.deleted,
        action: this.makePassive.bind(this, params.node.data.id)
      },
      {
        name: 'Kullanıcı Aktifleştir',
        disabled: !params.node.data.deleted,
        action: this.makeActive.bind(this, params.node.data.id)
      },
      {
        name: 'Kullanıcı Düzenle',
        action: this.editProduct.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      },
      {
        name: 'Kupon Tanımla',
        action: () => {
          this.setState({showCouponDialog: true, selectedUserId: params.node.data.id});
        }
      }
    ];

    return menu;
  };

  async makeActive(userId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", userId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/user/active-user', 'POST',
      headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  async makePassive(userId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", userId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/user/passive-user', 'POST',
      headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  updagePassword = async () => {
    if (this.state.newPassword !== this.state.newPasswordAgain) {
      Mesaj.show('Şifreler uyuşmamaktadır!', 'Uyarı', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
      return;
    }

    let body = {
      userId: this.state.selectedUserId,
      newPassword: this.state.newPassword
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/user/update-user-password',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.closeModal();
    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  assignCoupon = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("couponId", this.state.selectedCouponId);
    urlencoded.append("userId", this.state.selectedUserId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/coupon/assign-coupon-to-user',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.setState({loading: false});
  }

  closeModal = () => {
    this.setState({showPasswordDialog: false});
  }

  closeCouponModal = () => {
    this.setState({showCouponDialog: false});
  }

  updateUser = async () => {
    if (!this.state.updateUserName || !this.state.updateUserLastname || !this.state.updateUserEmail
      || !this.state.updateUserPhone || !this.state.updateUserFlatNo || !this.state.updateUserProjectId
      || !this.state.updateUserRole) {
      Mesaj.show('Bütün alanlar doldurulmalıdır!', 'Uyarı', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
      return;
    }

    let body = {
      userId: this.state.selectedUserId,
      name: this.state.updateUserName,
      lastname: this.state.updateUserLastname,
      email: this.state.updateUserEmail,
      phone: this.state.updateUserPhone,
      flatNo: this.state.updateUserFlatNo,
      projectId: this.state.updateUserProjectId,
      role: this.state.updateUserRole
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/user/update-user', 'POST',
      Util.HEADERS_FOR_GET, JSON.stringify(body));

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.closeUpdateModal();
    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData(this.state.selectedUserId).then();
  }

  closeUpdateModal = () => {
    this.setState({showUpdatedDialog: false});
  }

  onChangeInput = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  clearInput() {
    this.state.formData.name.value = '';
    this.state.formData.lastname.value = '';
    this.state.formData.email.value = '';
    this.state.formData.phone.value = '';
    this.state.formData.password.value = '';
    this.formDataUpdater(this.state.formData);
  }

  createUser = async () => {
    if (!this.state.formData.name.value) {
      Mesaj.show('Ad boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.lastname.value) {
      Mesaj.show('Soyad boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.email.value) {
      Mesaj.show('E-Posta boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.phone.value || this.state.formData.phone.value.length !== 10) {
      Mesaj.show('Telefon boş olamaz veya boyutu uygun değildir!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.password.value) {
      Mesaj.show('Şifre boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.yetki.value) {
      Mesaj.show('Yetki boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({loading: true});
    const body = {
      name: this.state.formData.name.value,
      lastname: this.state.formData.lastname.value,
      email: this.state.formData.email.value,
      phone: this.state.formData.phone.value,
      password: this.state.formData.password.value,
      role: this.state.formData.yetki.value,
      projectId: this.state.formData.projectId.value || 1,
      flatNo: this.state.formData.flatNo.value
    }

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/create-user-from-portal',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));
    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.clearInput();
    Mesaj.show('Kullanıcı eklendi.', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  yenile = () => {
    this.loadData().then();
  }

  onChangeCoupon = (event) => {
    // console.log('event.target.value : ', event.target.value, ', event.target.name : '
    //   , event.target.name);
    this.setState({[event.target.name]: event.target.value.toString()});
  }

  render() {
    return <div className={styles.mainContainer}>
      {this.state.loading &&
      <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9999}}/>}

      <Dialog
        open={this.state.showPasswordDialog}
        onClose={this.closeModal}
        style={{zIndex: '9900'}}
      >
        <DialogTitle id="form-dialog-title"
                     style={{textAlign: 'center'}}>Şifre Güncelleme</DialogTitle>
        <DialogContent style={{padding: '20px'}}>
          <TextField label="Yeni Şifre Giriniz." variant="outlined" value={this.state.newPassword} size='small'
                     name='newPassword'
                     onChange={this.onChangeInput}
                     type='password'/>
          <br/>
          <br/>
          <TextField label="Şifreyi Tekrar Giriniz." variant="outlined" value={this.state.newPasswordAgain} size='small'
                     name='newPasswordAgain'
                     onChange={this.onChangeInput}
                     type='password'/>
          <br/>
          <br/>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.updagePassword}
          >
            Güncelle
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={this.state.showCouponDialog}
        onClose={this.closeCouponModal}
        style={{zIndex: '9900'}}
      >
        <DialogTitle id="form-dialog-title"
                     style={{textAlign: 'center'}}>Kupon Tanımla</DialogTitle>
        <DialogContent style={{padding: '20px'}}>
          <select
            name='selectedCouponId'
            onChange={this.onChangeCoupon}
            value={this.state.selectedCouponId}
          >{this.coupons}</select>
          <br/>
          <br/>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            onClick={this.assignCoupon}
          >
            Tanımla
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={this.state.showUpdatedDialog}
        onClose={this.closeUpdateModal}
        style={{zIndex: '9900'}}
        fullWidth
      >
        <DialogTitle id="form-dialog-title"
                     style={{textAlign: 'center'}}>Güncelle</DialogTitle>
        <DialogContent style={{padding: '20px'}}>
          <TextField label="Ad" variant="outlined" value={this.state.updateUserName} size='small'
                     name='updateUserName'
                     onChange={this.onChangeInput}
                     fullWidth
                     type='text'/>
          <br/>
          <br/>
          <TextField label="Soyad" variant="outlined" value={this.state.updateUserLastname} size='small'
                     name='updateUserLastname'
                     onChange={this.onChangeInput}
                     fullWidth
                     type='text'/>
          <br/>
          <br/>
          <TextField label="E-Posta" variant="outlined" value={this.state.updateUserEmail} size='small'
                     name='updateUserEmail'
                     onChange={this.onChangeInput}
                     fullWidth
                     type='text'/>
          <br/>
          <br/>
          <TextField label="Telefon" variant="outlined" value={this.state.updateUserPhone} size='small'
                     name='updateUserPhone'
                     onChange={this.onChangeInput}
                     fullWidth
                     type='text'/>
          <br/>
          <br/>
          <TextField label="Proje Id" variant="outlined" value={this.state.updateUserProjectId} size='small'
                     disabled={this.isAdmin ? false : true}
                     name='updateUserProjectId'
                     onChange={this.onChangeInput}
                     fullWidth
                     type='number'/>
          <br/>
          <br/>
          <TextField label="Daire No" variant="outlined" value={this.state.updateUserFlatNo} size='small'
                     name='updateUserFlatNo'
                     onChange={this.onChangeInput}
                     fullWidth
                     type='number'/>
          <br/>
          <br/>
          <select name='updateUserRole'
                  onChange={this.onChangeInput}
                  value={this.state.updateUserRole}
          >
            <option value="admin">Admin</option>
            <option value="moderator">moderatör</option>
            <option value="mobile_app_user">Mobil Kullanıcı</option>
            <option value="elevator_user">Asansör Kullanıcısı</option>
            <option value="zorlu_user">Zorlu Kullanıcısı</option>
          </select>
          <br/>
          <br/>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.updateUser}
          >
            Güncelle
          </Button>
        </DialogContent>
      </Dialog>

      <InputForm
        formData={this.state.formData}
        formDataUpdater={this.formDataUpdater}
        inputParams={this.inputParams}
        inputFormColumnWidth={'50%'}
        tabLoop={true}
        customTabKeyCode={13}
      />

      <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
        <Button
          disabled={this.state.loading}
          variant={"contained"}
          color="primary"
          size="small"
          startIcon={<SaveIcon/>}
          onClick={this.createUser}
        >
          Kullanıcı Ekle
        </Button>

        <Button
          style={{marginLeft: '20px'}}
          disabled={this.state.loading}
          variant={"contained"}
          color="primary"
          size="small"
          onClick={this.yenile}
        >
          Yenile
        </Button>
      </div>

      <div>
        <Toolbar handlers={
          [{callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'}]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 220px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          getRowClass={this.getRowClass}
          getContextMenuItems={this.getContextMenuItems}
        >
        </Grid>
      </div>
    </div>
  };
}

export default User;
