import React, { useState } from 'react';

export const RobotRemoteContext = React.createContext({
  lang: 'tr',
  setLang: lang => {}
});

const RobotRemoteContextProvider = props => {
  const [currentLang, setCurrentLang] = useState(
    sessionStorage.getItem('language')
  );
  const data = {
    lang: currentLang,
    setLang: setCurrentLang
  };

  return (
    <RobotRemoteContext.Provider value={data}>
      {props.children}
    </RobotRemoteContext.Provider>
  );
};

export default RobotRemoteContextProvider;
