const getTargets = (siteName, floorName, targets) => {

  if (targets == undefined) {
    return [];
  }
  return targets.filter(
    element => element.site === siteName && element.floor === floorName
  );
};

const getFloors = (siteName,floors) => {
  if (floors == undefined) {
    return [];
  }
  return floors.filter(
    element => element.site === siteName
  );
};

const getSites = (sites) => {
  if (sites == undefined) {
    return [];
  }
  return sites.map(
    element => element
  );
};

export {
  getTargets,
  getFloors,
  getSites
};
