import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Badge, Hidden, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import LoginUtil from "../../../../utils/LoginUtil";
import Button from "@material-ui/core/Button";
import DateRangeIcon from '@material-ui/icons/DateRange';
import store from "../../../../redux/store/store";
import {SHOW_COMPANY_SELECT_MODAL} from "../../../../redux/actions/actionTypes";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const {className, onSidebarOpen, domainType, selectedCompany, selectedPeriod, ...rest} = props;

  let periodButtonText = "Dönem Seçiniz";

  if (domainType !== 2 && selectedPeriod) {
    periodButtonText = "Dönem:" + selectedPeriod.periodName;
  }


  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <div style={{
      backgroundColor: '#E95F43',
      position: 'fixed',
      display: 'flex',
      top: '0',
      left: '0',
      right: '0',
      zIndex: '1200',
      color: 'white'
    }}>
      {/*<AppBar
       {...rest}
       className={classes.toolbar}
     >*/}
      {/*{showCompanyPeriodDialog && LoginUtil.openCompanyPeriodDialog(handleCloseModal)}*/}
      {/*<CompanyPeriodSelectDialog closeHandler={null}/>*/}
      <div style={{margin: "auto", paddingLeft: '1%'}}>
        <RouterLink to="/">
          <img
            style={{height: '55px'}}
            alt="Logo"
            src="/images/logos/speedy_logo2.png"
          />
        </RouterLink>
      </div>
      <div className={classes.flexGrow}/>
      <Hidden mdDown>
        {selectedCompany &&
        <div style={{position: "absolute", top: '50%', left: "50%", transform: "translateX(-50%) translateY(-50%)"}}
             title={selectedCompany.companyDesc}>{"Seçilen Firma:" + selectedCompany.companyName}</div>}
        <div>
          {domainType === 1 &&
          <Button className={classes.button}
                  onClick={() => store.dispatch({
                    type: SHOW_COMPANY_SELECT_MODAL,
                    showCompanySelectModal: true
                  })}
                  variant="contained"
                  color="default"
                  startIcon={<DateRangeIcon/>}>
            {periodButtonText}
          </Button>}
        </div>
        <IconButton color="inherit">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon/>
          </Badge>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          title="Çıkış"
          onClick={() => LoginUtil.logout()}
        >
          <InputIcon/>
        </IconButton>
      </Hidden>
      <Hidden lgUp>
        <IconButton
          color="inherit"
          onClick={onSidebarOpen}
        >
          <MenuIcon/>
        </IconButton>
      </Hidden>
      {/*</AppBar>*/}
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
