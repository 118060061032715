import Switch from '@material-ui/core/Switch';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
const LiveSwitch = ({ isEnable, handleChange, isDisabled }) => {
  const classes = useStyles();
  return (
    <Switch
      checked={isEnable}
      checkedIcon={
        <PlayArrowIcon
          style={{
            border: '1px solid',
            borderRadius: '20.4px',
            backgroundColor: '#1d1d1d'
          }}
        />
      }
      disabled={isDisabled}
      icon={
        <PauseIcon
          style={{
            border: '1px solid',
            borderRadius: '20.4px',
            backgroundColor: '#1d1d1d'
          }}
        />
      }
      onChange={handleChange}
      classes={{
        track: classes.track,
        checked: classes.checked,
        switchBase: classes.switchBase
      }}
      name="checkedB"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );
};
const useStyles = makeStyles({
  rootRight: {
    position: 'absolute',
    right: '25px',
    top: '-15px'
  },
  rootLeft: {
    position: 'absolute',
    right: '0',
    bottom: '-2px',
    height: '40px',
    width: '64px'
  },
  track: {
    opacity: 1,
    backgroundColor: '#ffffff54!important'
  },
  checked: {
    color: '#ff1414!important'
  },
  switchBase: {
    top: '-1px',
    left: '0',
    color: '#317ffb'
  }
});
export default LiveSwitch;
