import Util from "./CommonUtil";
import store from "../redux/store/store";
import {SIGNOUT} from "../redux/actions/actionTypes";
import {Consts} from "../const/Consts";

const LoginUtil = {
  login: async function login(phone, password, smsCode) {
    LoginUtil.clearProfileAndToken();

    let headers = {
      'Content-Type': 'application/json'
    };

    const body = {
      phone: phone,
      password: password,
      smsCode: smsCode
    };

    let ret = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/sign-in-with-sms',
      'POST', headers, JSON.stringify(body) );

    if (ret.result===Consts.RESULT_ERROR || ret.errorCode || ret.error || (ret.result && ret.result === 'Error'))
      return ret;

    let profileObj = Object.assign({}, ret);
    delete profileObj.token;

    sessionStorage.setItem("profile", JSON.stringify(profileObj));
    sessionStorage.setItem('token', ret.token);

    return true;
  },
  sendSmsCode: async function sendSmsCode(phone, password) {
    let headers = {
      'Content-Type': 'application/json'
    };

    const body = {
      phone: phone,
      password: password
    };

    let ret = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/create-sms-code', 'POST', headers, JSON.stringify(body) );

    if (ret.result===Consts.RESULT_ERROR || ret.errorCode || ret.error || (ret.result && ret.result === 'Error'))
      return ret;

    return true;
  },
  getProfile: function () {
    const sessionStr= sessionStorage.getItem("profile");
    return JSON.parse(sessionStr);
  },
  logout: function logout() {
    LoginUtil.clearProfileAndToken();
    store.dispatch({type: SIGNOUT});
    window.location.href = "/sign-in";
  },
  clearProfileAndToken: function clearProfileAndToken() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('profile');
  },

  loggedInUserInfo: function loggedInUserInfo() {
    let token = sessionStorage.getItem('token');
    if (!token || token === 'undefined') {
      return null;
    }

    let jwtObj = Util.parseJwt(token);
    return {
      name: jwtObj.username,
      email: jwtObj.email
    };
  },
  //İlerde JWt içindeki geçerlilik zamanları da kontrol edilebilir.
  isLoggedIn: function isLoggedIn() {
    let token = sessionStorage.getItem('token');
    if (!token || token === 'undefined') {
      return false;
    }
    return true;
  },
  getToken: function (getToken) {
    return sessionStorage.getItem('token');
  }

}

export default LoginUtil;
